import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { ImportsModule } from 'shared';

@Component({
  selector: 'app-prescription-referral',
  standalone: true,
  imports: [ImportsModule, TabViewModule],
  templateUrl: './prescription-referral.component.html',
  styleUrl: './prescription-referral.component.scss',
})
export class PrescriptionReferralComponent implements OnInit {
  amka: string = '';
  referralCode: string = '';
  referralResults: any[] = [];

  // Dummy data
  private referrals = [
    { referralNumber: '12345', referralCode: 'ABC123', deadlineDate: new Date(), status: 'Pending', userId: '1' },
    { referralNumber: '67890', referralCode: 'DEF456', deadlineDate: new Date(), status: 'Completed', userId: '2' }
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onSearchByAmka(): void {
    this.referralResults = this.searchReferrals(this.amka, null);
  }

  onSearchByReferralCode(): void {
    this.referralResults = this.searchReferrals(null, this.referralCode);
  }

  searchReferrals(amka: string | null, referralCode: string | null): any[] {
    // Replace this with actual search logic
    return this.referrals.filter(referral =>
      (amka ? referral.referralNumber.includes(amka) : true) &&
      (referralCode ? referral.referralCode.includes(referralCode) : true)
    );
  }
}