
<div class="col-12">
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Έλεγχος Ασφαλιστικής Κάλυψης</h5>
        <div class="flex justify-content-between flex-wrap row-gap-2">
          <div class="flex flex-wrap gap-5">
            <input
              pInputText
              type="text"
              
              placeholder="ΑΜΚΑ Ασθενή"
            />
            <!-- <input
              pInputText
              type="text"
              (input)="onGlobalFilter(dt2, $event)"
              placeholder="Κωδικός Επίσκεψης"
            />
            <p-calendar
              [(ngModel)]="date"
              [showIcon]="true"
              [showOnFocus]="false"
              inputId="buttondisplay"
            /> -->
            <button pButton >Αναζήτηση</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      
<div class="col-12">
  <div class="grid">
    <!-- card -->
    <div class="col-12">
      <div class="card p-0 text-700 flex flex-column justify-content-between h-full bg-primary-500 border-round-3xl">
        <div class="flex flex-row p-4 gap-5">
          <span class="font-bold text-color text-xl">Βασικές Πληροφορίες Κάλυψης​​</span>
        </div>
        <div class="flex-row surface-card p-4 border-round-bottom-3xl">
          <div class="col-12 flex flex-wrap align-items-center mb-3">
            <span class="font-bold text-lg">Ονοματεπώνυμο:​&nbsp;</span>
            <span class="text-lg">Μπουντούλης Χρήστος</span>
          </div>
          <div class="col-12 flex flex-wrap align-items-center mb-3">
            <span class="font-bold text-lg">Α.Μ.Κ.Α.:&nbsp;</span>
            <span class="text-lg">xxx​xxxxxxx</span>
          </div>
          <div class="col-12 flex flex-wrap align-items-center mb-3">
            <span class="font-bold text-lg">Ιδιωτική Ασφάλιση:&nbsp;</span>
            <span class="text-lg">Ναι</span>
          </div>
          <div class="col-12 flex flex-wrap align-items-center mb-3">
            <span class="font-bold text-lg">Ασφ. Εταιρεία:&nbsp;​</span>
            <span class="text-lg">Εθνική Ασφαλιστική</span>
          </div>
          <div class="col-12 flex flex-wrap align-items-center mb-3">
            <span class="font-bold text-lg">Ασφαλιστική Ικανότητα:&nbsp;​</span>
            <span class="text-lg">Έως 30/08/2025</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12">
  <div class="grid">
    <!-- card -->
    <div class="col-12 sm:col-6 xl:col-6">
      <div class="card p-0 text-700 flex flex-column justify-content-between h-full bg-primary-500 border-round-3xl">
        <div class="flex flex-row justify-content-center align-items-center p-4 gap-5">
          <span class="font-bold text-color text-xl">Όριο Καλύψεων για Πρωτοβάθμια</span>
        </div>
        <div class="flex flex-row surface-card gap-5 p-4 justify-content-center border-round-bottom-3xl">
          <div class="col-6 sm:col-3 xl:col-3 text-center">
            <span class="font-bold text-lg">Συνολικό ποσό: </span><span class="text-4xl font-bold">1,500€</span>
          </div>
          <div class="col-6 sm:col-3 xl:col-3 text-center">
            <span class="font-bold text-lg">Διαθέσιμο ποσό: </span><span class="text-4xl font-bold text-primary">900€</span>
          </div>
        </div>
      </div>
    </div>
    <!-- card -->
    <div class="col-12 sm:col-6 xl:col-6">
      <div class="card p-0 text-700 flex flex-column justify-content-between h-full bg-primary-500 border-round-3xl">
        <div class="flex flex-row justify-content-center align-items-center p-4 gap-5">
          <span class="font-bold text-color text-xl">Όριο Καλύψεων για Δευτεροβάθμια</span>
        </div>
        <div class="flex flex-row surface-card gap-5 p-4 justify-content-center border-round-bottom-3xl">
          <div class="col-6 sm:col-3 xl:col-3 text-center">
            <span class="font-bold text-lg">Συνολικό ποσό: </span><span class="text-4xl font-bold">15,000€</span>
          </div>
          <div class="col-6 sm:col-3 xl:col-3 text-center">
            <span class="font-bold text-lg">Διαθέσιμο ποσό: </span><span class="text-4xl font-bold text-primary">9,000€</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        
  
