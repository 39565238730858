import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImportsModule, MemberService } from 'shared';
import { SidebarModule } from 'primeng/sidebar';
import { Table } from 'primeng/table';
import { BadgeModule } from 'primeng/badge';


@Component({
  selector: 'app-manage-visits',
  standalone: true,
  imports: [ImportsModule, SidebarModule, BadgeModule],
  templateUrl: './manage-visits.component.html',
  styleUrl: './manage-visits.component.scss',
})
export class ManageVisitsComponent implements OnInit, OnDestroy {
  visits: any;
  sidebarVisible: boolean = false;
  statuses!: any[];
  value: any;
  representatives: any;

  subscriptions: Subscription[] = [];
  item: any;
  visible: boolean = false;
  dataLoaded: boolean = false;
  constructor(private insuredService: MemberService) {}
  ngOnInit(): void {
    this.visits = [
      {
        visitCode: 'V001',
        startDate: '2024-08-25',
        endDate: '2024-08-26',
        patient: 'Ιωάννης Δοέ',
        amka: 'AMKA123456789',
        type: 'Νοσηλεία',
        cost: '100.00€',
        status: 'Ολοκληρωμένο', // Ensure this matches the string in ngClass
      },
      {
        visitCode: 'V002',
        startDate: '2024-08-27',
        endDate: '2024-08-28',
        patient: 'Μαρία Παπαδοπούλου',
        amka: 'AMKA987654321',
        type: 'Εξωτερικά Ιατρεία',
        cost: '150.00€',
        status: 'Επερχόμενο',
      },
      {
        visitCode: 'V003',
        startDate: '2024-08-29',
        endDate: '2024-08-30',
        patient: 'Νίκος Σταθόπουλος',
        amka: 'AMKA456123789',
        type: 'Νοσηλεία',
        cost: '200.00€',
        status: 'Ολοκληρωμένο',
      },
      {
        visitCode: 'V004',
        startDate: '2024-09-01',
        endDate: '2024-09-02',
        patient: 'Άννα Παππά',
        amka: 'AMKA321654987',
        type: 'Εξωτερικά Ιατρεία',
        cost: '120.00€',
        status: 'Ακυρωμένο',
      },
      {
        visitCode: 'V005',
        startDate: '2024-09-03',
        endDate: '2024-09-04',
        patient: 'Μιχάλης Αντωνίου',
        amka: 'AMKA654987321',
        type: 'Νοσηλεία',
        cost: '180.00€',
        status: 'Επερχόμενο',
      },
    ];
    

    this.dataLoaded = true;
   }
  ngOnDestroy() {
    this.subscriptions.pop();
  }

  viewDetails(item: any) {
    this.item = item;
    this.visible = true;
  }

  toggleSidebar() {
    this.sidebarVisible = true;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }
}
