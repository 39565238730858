import { Component, OnDestroy, OnInit } from '@angular/core';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { FiltersComponent, ImportsModule } from 'shared';
import { Doctor, DoctorService } from 'shared';
import { Subscription } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrl: './visit-form.component.scss',
  standalone: true,
  imports: [
    AccordionModule,
    DoctorProfileComponent,
    DropdownModule,
    FormsModule,
    ButtonModule,FiltersComponent
  ],
})
export class VisitFormComponent implements OnInit, OnDestroy {
  areaDropdown: any[];
  specialtyDropdown: any[];
  selectedOption1: any;
  selectedOption2: any;
  filteredDoctors: Doctor[] = [];

  temp: any;
  subscriptions: Subscription[] = [];
  dataLoaded: boolean = false;

  selectedRegion!: string;
  selectedSpecialty!: string;

  doctors: Doctor[] = [];
  doctorsFiltered: boolean = false;

  constructor(private doctorService: DoctorService) {
    this.areaDropdown = [
      { label: 'Κέντρο', value: 'kentro' },
      { label: 'Βόρεια Προάστεια', value: 'voreia-proasteia' },
      { label: 'Νότια Προάστεια', value: 'notia-proasteia' },
      { label: 'Δυτικά Προάστεια', value: 'ditika-proasteia' },
      { label: 'Ανατολικά Προάστεια', value: 'anatolika-proasteia' },
    ];

    this.specialtyDropdown = [
      { label: 'Αθλητίατρος', value: 'Αθλητίατρος' },
      { label: 'Ορθοπεδικός', value: 'Ορθοπεδικός' },
      { label: 'Παθολόγος', value: 'Παθολόγος' },
      { label: 'Παιδίατρος', value: 'Παιδίατρος' },
    ];
  }

  ngOnInit() {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fetchData() {
    let specialties: any;
    let areas: any;
    const doctorServiceSubscription = this.doctorService
      .getAllDoctors()
      .subscribe({
        next: (result) => {
          if (result) {
            // console.log(result);
            this.doctors = result.data;

            areas = [
              ...new Set(
                this.doctors.map((items) => {
                  return items.area;
                })
              ),
            ];
            specialties = [
              ...new Set(
                this.doctors.map((items) => {
                  return items.specialty.name;
                })
              ),
            ];
            this.areaDropdown = areas.map((item: any) => ({
              label: item,
              value: item,
            }));
            this.specialtyDropdown = specialties.map((item: any) => ({
              label: item,
              value: item,
            }));
            this.temp = this.doctors;
          }
        },
        error: (error) => {
          console.error('Error fetching doctors:', error);
        },
        complete: () => {
          this.dataLoaded = true;
        },
      });

    this.subscriptions.push(doctorServiceSubscription);
  }

}
