<div class="grid gap-2">
  <div class="col-12">
<p-card>
  <p-accordion [activeIndex]="0">
    <p-accordionTab header="Αναζήτηση Παραπεμπτικού" >
      <div class="grid">
        <!-- Row 1: AMKA Field and Search Button -->
        <div class="col-12 md:col-6">
          <div class="grid">
            <div class="col-12 sm:col-6">
              <label for="amka">ΑΜΚΑ</label><br>
              <input id="amka" type="text" pInputText [(ngModel)]="amka" />
            </div>
            <div class="col-12 sm:col-6 flex align-items-end">
              <button pButton type="button" label="Αναζήτηση" (click)="onSearchByAmka()" style="width: 100%;"></button>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6"></div>

        <!-- Row 2: Referral Code Field and Search Button -->
        <div class="col-12 md:col-6">
          <div class="grid">
            <div class="col-12 sm:col-6">
              <label for="referralCode">Κωδικός Παραπεμπτικού</label><br>
              <input id="referralCode" type="text" pInputText [(ngModel)]="referralCode" />
            </div>
            <div class="col-12 sm:col-6 flex align-items-end">
              <button pButton type="button" label="Αναζήτηση" (click)="onSearchByReferralCode()" style="width: 100%;"></button>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6"></div>
      </div>
    </p-accordionTab>
  </p-accordion>
</p-card>
</div>
<!-- Referral Results Card -->
 <div class="col-12">
<p-card header="Παραπεμπτικό" *ngIf="referralResults?.length">
  <p-table [value]="referralResults">
    <ng-template pTemplate="header">
      <tr>
        <th>Αριθμός Παραπεμπτικού</th>
        <th>Κωδικός Παραπεμπτικού</th>
        <th>Ημερομηνία Προθεσμίας Εκτέλεσης</th>
        <th>Κατάσταση Παραπεμπτικού</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-referral>
      <tr>
        <td>{{ referral.referralNumber }}</td>
        <td>{{ referral.referralCode }}</td>
        <td>{{ referral.deadlineDate | date: 'dd/MM/yyyy' }}</td>
        <td>{{ referral.status }}</td>
        <td>
          <a [routerLink]="['/prescription-details', referral.userId]">
            <i class="pi pi-arrow-circle-right"></i>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
</div>
</div>
