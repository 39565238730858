import { Component, Input } from '@angular/core';
import { DoctorService } from 'shared';
import { forkJoin, Subscription } from 'rxjs';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrl: './doctor-profile.component.scss',
  standalone: true,
  imports: [RatingModule, CommonModule, RouterLink, FormsModule],
})
export class DoctorProfileComponent {
  @Input() doctorData: any;
  rating: any;

  showMore: boolean = false;

  availabilitySlots: any;

  addresses: any;
  subscriptions: Subscription[] = [];

  constructor(private doctorService: DoctorService) {}

  ngOnInit() {
    this.rating = '9.9';
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fetchData() {}

  toggleMore() {
    this.showMore = !this.showMore;
  }

  get experiences(): string[] {
    return this.doctorData?.experiences || [];
  }
}
