import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';

@Component({
  selector: 'app-coverage',
  standalone: true,
  imports: [
    BadgeModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    FormsModule,
  ],
  templateUrl: './coverage.component.html',
  styleUrl: './coverage.component.scss',
})
export class CoverageComponent {
  onGlobalFilter(_t12: Table, $event: Event) {
    throw new Error('Method not implemented.');
  }
  toggleSidebar() {
    throw new Error('Method not implemented.');
  }
  date: any;
  exams: any = [
    {
      visitCode: 'V001',
      amka: 'AMKA123456789',
      examType: 'Αιματολογική Εξέταση',
      status: 'Ολοκληρωμένο',
    },
    {
      visitCode: 'V002',
      amka: 'AMKA987654321',
      examType: 'Ακτινογραφία',
      status: 'Εκκρεμεί',
    },
    {
      visitCode: 'V003',
      amka: 'AMKA456123789',
      examType: 'Ηλεκτροκαρδιογράφημα',
      status: 'Ολοκληρωμένο',
    },
    {
      visitCode: 'V004',
      amka: 'AMKA321654987',
      examType: 'Μαγνητική Τομογραφία',
      status: 'Εκκρεμεί',
    },
    {
      visitCode: 'V005',
      amka: 'AMKA654987321',
      examType: 'Υπέρηχος',
      status: 'Ολοκληρωμένο',
    },
  ];
  dataLoaded: boolean = true;
}
