
    <div class="card">
      <h5>Λίστα Περιστατικών</h5>
      <p-table
        #dt2
        [value]="visits"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [paginator]="true"
        [lazy]="false"
        [globalFilterFields]="['socialSecurityNumber', 'fullName', 'gender', 'email']"
        [tableStyle]="{ 'min-width': '70rem' }"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-wrap row-gap-2">
            <div class="flex flex-wrap gap-3">
              <input
                pInputText
                type="text"
                (input)="onGlobalFilter(dt2,$event)"
                placeholder="Αναζήτηση"
              />
              <div
                pButton
                pRipple
                class="p-button-secondary flex gap-2"
                (click)="toggleSidebar()"
              >
                <i class="pi pi-sliders-h"></i>
                <span>Φίλτρα</span>
              </div>
            </div>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Ασθενή"
              class="p-button-success"
            ></button> -->
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Κωδ. Περιστατικού</th>
            <th>Ημερομηνία Έναρξης</th>
            <th>Ημερομηνία Ολοκλήρωσης</th>
            <th>Ασθενής</th>
            <th>ΑΜΚΑ</th>
            <th>Τύπος</th>
            <th>Κατάσταση</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-visit>
          <tr>
            <td>{{ visit.visitCode }}</td>
            <td>{{ visit.startDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ visit.endDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ visit.patient }}</td>
            <td>{{ visit.amka }}</td>
            <td>{{ visit.type }}</td>
            <td>
              <span
                [ngClass]="{
                  'p-badge-success': visit.status === 'Ολοκληρωμένο',
                  'p-badge-warning': visit.status === 'Επερχόμενο',
                  'p-badge-danger': visit.status === 'Ακυρωμένο'
                }"
                class="p-badge"
              >
                {{ visit.status }}
              </span>
            </td>
            <td>
              <a
                class="hover:text-orange-700 font-bold cursor-pointer"
                [routerLink]="['/patient-details/', visit.visitCode]"
              >
                <i class="pi pi-ellipsis-v"></i>
              </a>
            </td>
          </tr>
        </ng-template>
        
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No customers found.</td>
          </tr>
        </ng-template>
      </p-table>
    
      <p-sidebar [(visible)]="sidebarVisible" styleClass="w-30rem">
        <div class="flex flex-column">
          <h3>Επιλογή Εξειδικευμένων Φίλτρων</h3>
    
          <div class="flex flex-row align-items-center gap-5">
            <button
              pButton
              pRipple
              class="p-button-success"
              label="Προβολή"
            ></button>
            <div pButton pRipple class="border-none bg-white">
              <i class="pi pi-sync text-4xl text-color text-bold"></i>
            </div>
          </div>
    
          <h6>Ημερομηνία Επίσκεψης</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field col-6">
                <label class="font-bold" htmlFor="name2">Άπο</label>
                <input pInputText id="name2" type="date" />
              </div>
              <div class="field col-6">
                <label class="font-bold" htmlFor="email2">Μέχρι</label>
                <input pInputText id="email2" type="date" />
              </div>
            </div>
            <h6>Ημερομηνία Γέννησης</h6>
            <div class="p-formgrid grid">
              <div class="field col-6">
                <label class="font-bold" htmlFor="name2">Άπο</label>
                <input pInputText id="name2" type="date" />
              </div>
              <div class="field col-6">
                <label class="font-bold" htmlFor="email2">Μέχρι</label>
                <input pInputText id="email2" type="date" />
              </div>
              <div class="field col-12">
                <label class="font-bold" htmlFor="name2">Φύλο</label>
                <input pInputText id="name2" type="text" />
              </div>
              <div class="field col-12">
                <label class="font-bold" htmlFor="email2">Με Παραπομπή</label>
                <input pInputText id="email2" type="text" />
              </div>
              <div class="field col-12">
                <label class="font-bold" htmlFor="name2">Με Γνωμάτευση</label>
                <input pInputText id="name2" type="text" />
              </div>
              <div class="field col-12">
                <label class="font-bold" htmlFor="email2">Είδος Εξέτασης</label>
                <input pInputText id="email2" type="text" />
              </div>
              <div class="field col-12">
                <label class="font-bold" htmlFor="email2">Κωδικός Διάγνωσης</label>
                <input pInputText id="email2" type="text" />
              </div>
            </div>
          </div>
        </div>
      </p-sidebar>
    </div>
