<div class="grid">
  <div class="col align-items-center"></div>

  <div class="col-12 card flex justify-content-between align-items-center">
    <p-menubar [model]="items" />
    <p-dropdown
      [options]="doctors"
      [(ngModel)]="selectedDoctor"
      optionLabel="name"
      placeholder="Επιλέξτε γιατρό"
      [optionValue]="doctors[0].label"
    >
    </p-dropdown>
  </div>
  @if(selectedMenuItem==="calendar"){
  <div class="col-12 card">
    <lib-full-calendar [appointments]="appointments"></lib-full-calendar>
  </div>
  }@else if(selectedMenuItem==="appointments"){
  <div class="col-12 card">
    <lib-appointments-table
      [(appointments)]="appointments"
      [(pendingAppointments)]="pendingAppointments"
      [tableHeaders]="tableHeaders"
    ></lib-appointments-table>
  </div>

  } @else if(selectedMenuItem==="pendingAppointments"){
  <div class="col-12 card">
    <lib-pending-appointments
      [(appointments)]="pendingAppointments"
      (changedAppointments)="getPendingAppointments($event)"
    ></lib-pending-appointments>
  </div>
  }
</div>
