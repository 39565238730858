<p-card>
  <h2 class="text-center mb-4">Παραπεμπτικό</h2>

  <div class="grid">
    <div class="col-12 md:col-4 mb-3 md:mb-0">
      <h3 class="text-primary">Στοιχεία Ασφαλισμένου</h3>
      <p><strong>Όνομα:</strong> {{ patient.name }}</p>
      <p><strong>AMKA:</strong> {{ patient.amka }}</p>
    </div>
    <div class="col-12 md:col-4">
      <h3 class="text-primary">Στοιχεία Γιατρού</h3>
      <p><strong>Όνομα:</strong> {{ doctor.name }}</p>
      <p><strong>Ειδικότητα:</strong> {{ doctor.specialty }}</p>
    </div>
    <div class="col-12 md:col-4">
      <h3 class="text-primary">Παραπεμπτικό</h3>
      <div style="display: flex; align-items: flex-start;">
        <i class="pi pi-qrcode" style="font-size: 60px; margin-right: 15px;"></i>
        <div>
          <p><strong>Αριθμός Παραπεμπτικού:</strong> {{ referral.referralNumber }}</p>
          <p><strong>Ημερομηνία Έκδοσης:</strong> {{ referral.issueDate | date }}</p>
          <p><strong>Ημερομηνία Λήξης:</strong> {{ referral.expiryDate | date }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mt-4">
    <div class="col-12">
      <h3 class="text-primary">Πληροφορίες</h3>
      <p><strong>Αιτιολογία:</strong> {{ referral.reason }}</p>
      <p><strong>Διάγνωση:</strong> {{ referral.diagnosis }}</p>
    </div>
  </div>

  <h3 class="mt-4 mb-3 text-primary">Παραπομπή για Εξετάσεις</h3>
  <p-table [value]="referral.tests" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>AA</th>
        <th>Κωδικός Εξέτασης</th>
        <th>Περιγραφή</th>
        <th>Τεμάχια</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-test let-i="rowIndex">
      <tr>
        <td>
          <p-checkbox [(ngModel)]="test.selected" binary="true"></p-checkbox>
        </td>
        <td>{{ i + 1 }}</td>
        <td>{{ test.code }}</td>
        <td>{{ test.description }}</td>
        <td>{{ test.quantity }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div class="grid mt-4 justify-content-center">
    <div class="col-12 sm:col-6 md:col-8" style="display: flex; justify-content: space-between;">
      <button pButton label="Μερική Εκτέλεση" class="p-button-primary w-full mr-2" (click)="onPartialExecution()"></button>
      <button pButton label="Εκτέλεση του Παραπεμπτικού" class="p-button-primary w-full" (click)="onFullExecution()"></button>
    </div>
  </div>
</p-card>



<!-- } @else{ } -->
