import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';

import { forkJoin, Subscription } from 'rxjs';
import { ExamsService, MemberService } from 'shared';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-patient-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    ButtonModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    TabViewModule,
    FileUploadModule,
    ToastModule,
    ListboxModule,
  ],
  templateUrl: './patient-details.component.html',
  styleUrl: './patient-details.component.scss',
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  diagnoses: any = [
    {
      icd10: 'A00',
      diagnosis: 'Χολέρα',
      comment: 'Χωρίς πρόσφατο ιστορικό ταξιδιού',
      event: 'Ξέσπασμα',
      startDate: new Date('2024-01-10'),
      outcome: 'Ανάρρωση',
      endDate: new Date('2024-01-20'),
    },
    {
      icd10: 'B20',
      diagnosis: 'Νόσος του HIV',
      comment: 'Υπό θεραπεία ART',
      event: 'Προγραμματισμένος έλεγχος',
      startDate: new Date('2023-12-05'),
      outcome: 'Σταθερή',
      endDate: new Date('2024-01-15'),
    },
    // Add more data as needed
  ];
  examinations = [
    {
      date: new Date('2024-08-20'),
      category: 'Αιματολογικές',
      examination: 'Ολική Αιματολογική Εξέταση',
      result: 'Κανονικά επίπεδα',
    },
    {
      date: new Date('2024-07-15'),
      category: 'Ακτινολογικές',
      examination: 'Ακτινογραφία Θώρακος',
      result: 'Χωρίς ευρήματα',
    },
    {
      date: new Date('2024-06-10'),
      category: 'Βιοχημικές',
      examination: 'Εξέταση Γλυκόζης',
      result: 'Ελαφρώς αυξημένη',
    },
    {
      date: new Date('2024-05-22'),
      category: 'Ουρολογικές',
      examination: 'Ανάλυση Ούρων',
      result: 'Χωρίς ανωμαλίες',
    },
  ];

  clinicalExams = [
    {
      date: new Date('2024-08-25'),
      examination: 'Κλινική εξέταση καρδιάς',
      notes: 'Κανονικός καρδιακός ρυθμός, χωρίς ανωμαλίες',
    },
    {
      date: new Date('2024-07-10'),
      examination: 'Εξέταση πνευμόνων',
      notes: 'Φυσιολογικοί ήχοι αναπνοής, χωρίς συριγμό',
    },
    {
      date: new Date('2024-06-18'),
      examination: 'Εξέταση αρθρώσεων',
      notes: 'Ήπιο οίδημα στο αριστερό γόνατο, προτείνεται παρακολούθηση',
    },
    {
      date: new Date('2024-05-30'),
      examination: 'Νευρολογική εξέταση',
      notes: 'Φυσιολογικά αντανακλαστικά, χωρίς νευρολογικές ανωμαλίες',
    },
  ];

  files: any = [
    {
      fileName: 'Patient_Record_01.pdf',
      dateOfUpload: new Date('2024-08-01'),
      fileSize: '1.5 MB',
    },
    {
      fileName: 'XRay_Image_02.png',
      dateOfUpload: new Date('2024-07-28'),
      fileSize: '2.3 MB',
    },
    {
      fileName: 'Blood_Test_Report_03.docx',
      dateOfUpload: new Date('2024-07-15'),
      fileSize: '250 KB',
    },
    {
      fileName: 'MRI_Scan_04.jpg',
      dateOfUpload: new Date('2024-06-30'),
      fileSize: '5.6 MB',
    },
    {
      fileName: 'Prescription_05.pdf',
      dateOfUpload: new Date('2024-05-22'),
      fileSize: '800 KB',
    },
  ];
  selectedRowExam: any;

  exams: any;
  selectedExams: any;
  textAreaValue: string = '';
  modalMessage: any;
  insured: any;

  selectedDateForAppointment: any;

  subscriptions: Subscription[] = [];
  name: any;
  uploadedFiles: any[] = [];

  showPrescription: boolean = false;
  showReference: boolean = false;
  showExamDetails: boolean = false;
  clinicName: any;
  examTableData: any;
  dropdownExamResults = [
    { name: 'Ολοκληρωμένο', code: 1 },
    { name: 'Εκκρεμεί', code: 2 },
  ];
  diagnosis: any;

  dataLoaded: boolean = false;
  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;

  patientId: number = 0;
  @Input() clinicId: number = 0;
  constructor(
    private route: ActivatedRoute,
    private insuredService: MemberService,
    private examService: ExamsService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  ngOnInit(): void {
    this.patientId = Number(this.route.snapshot.paramMap.get('id'));
    this.clinicName = JSON.parse(localStorage.getItem('token')!);

    this.clinicName.userName = this.clinicName.userName
      .split('_') // Split into ["rachel", "stark"]
      .map((namePart: any) => {
        return namePart.charAt(0).toUpperCase() + namePart.slice(1);
      }) // Capitalize each part
      .join(' ');

    this.clinicId = 90;
    // TODO Fix the static data
    const examServiceSubscription$ = this.examService
      .getExamRefByUserAndClinicId(this.patientId, this.clinicId)
      // .getExamRefByUserAndClinicId(25, 49)
      .subscribe({
        next: (examRef: any) => {
          console.log(examRef);
          this.examTableData = examRef.data;
          this.selectedRowExam = this.examTableData[0];
          this.selectedRowExam?.examTypes.forEach((examType: any) => {
            examType.isSubmitted = false;
          });
          let cost: number = 0;
          this.examTableData = this.examTableData.map((exam: any) => ({
            ...exam,
            totalCost: exam.costs.reduce((a: any, b: any) => a + b, 0),
          }));
        },
        error(err) {
          console.error('error fetching exam refs', err);
        },
      });
    this.subscriptions.push(examServiceSubscription$);

    const insuredSubscription$ = this.insuredService.getMemberInfoByMemberId(
      this.patientId
    );

    const subscriptions$ = forkJoin([insuredSubscription$]).subscribe({
      next: ([insuredResult]) => {
        if (insuredResult) {
          console.log(insuredResult);
          this.insured = insuredResult.data;
        }
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
      complete: () => {
        this.dataLoaded = true;
      },
    });

    this.subscriptions.push(subscriptions$);
  }
  ngOnDestroy(): void {
    this.subscriptions.pop();
  }

  onSubmitExams(form: NgForm, examType: any) {
    if (form.valid) {
      const date = new Date();
      let examResult: any = {
        examId: this.selectedRowExam.examId,
        examTypeId: examType.examTypeId,
        diagnosis: form.value.diagnosis,
        completionDate: date.toISOString().slice(0, 10),
      };
      console.log(examResult);
      this.examService.addExamResult(examResult).subscribe({
        next: (res) => {
          console.log(res);
          examType.isSubmitted = true;
          // this.submitDisable = true;
          // this.successVisible = true;
          // this.closeModal();
        },
        error: (err) => {
          console.error(err);
          // this.submitDisable = true;
        },
        complete: () => {},
      });
    }
  }

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully' });
  }

  onImageMouseOver(file: any) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'flex')
        : null;
    });
  }

  onImageMouseLeave(file: any) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'none')
        : null;
    });
  }

  removeImage(event: Event, file: any) {
    event.stopPropagation();
    this.uploadedFiles = this.uploadedFiles.filter((i) => i !== file);
  }

  showAddPrescription() {
    this.showPrescription = true;
  }
  openExamDetails(exam: any) {
    this.selectedRowExam = exam; // Clone the object to avoid direct mutation
    this.showExamDetails = true;
    console.log('row exam', this.selectedRowExam);
  }

  addReference() {
    if (
      (this.selectedDateForAppointment != null ||
        this.selectedDateForAppointment != '') &&
      (!this.selectedExams || this.selectedExams.length > 0) &&
      this.textAreaValue != null
    ) {
      let reference: any = {
        userId: this.patientId,
        requestUserId: this.clinicId,
        startDate: this.formatDate(this.selectedDateForAppointment),
        expiryDate: '2024-12-02',
        examTypeId: this.selectedExams.map((item: any) => {
          return item.code;
        }),
        notes: this.textAreaValue,
      };
      const createExamSubscription$ = this.examService
        .createExams(reference)
        .subscribe({
          next: (res) => {
            this.modalMessage = this.formValidator(res);
            console.log(res);
          },
          error: (err) => {
            alert('Κάτι πήγε στραβά!');
            console.error(err);
          },
          complete: () => {
            /*checks if the validation message from the api is null,
             which means no validation errors and closes the modal */
            if (this.modalMessage == null) {
              this.showReference = false;
              alert('Καταχωρήθηκε Επιτυχώς!');
            }
          },
        });

      this.subscriptions.push(createExamSubscription$);
    }
  }
  formValidator(message: any) {
    if (message.validationMessages) {
      return message.validationMessages.map((m: any) => {
        return m;
      });
    } else {
      return null;
    }
  }

  addMedicine() {
    // Reference the parent container where the new row will be added
    const parent = this.el.nativeElement.querySelector('#medicines');

    // Create the new row container
    const newRow = this.renderer.createElement('div');
    this.renderer.addClass(newRow, 'flex');
    this.renderer.addClass(newRow, 'gap-5');
    this.renderer.addClass(newRow, 'align-items-center'); // Align items vertically centered

    // Create the first div (for the "Φάρμακο" input)
    const medicineDiv = this.renderer.createElement('div');
    this.renderer.addClass(medicineDiv, 'flex');
    this.renderer.addClass(medicineDiv, 'flex-column');
    this.renderer.addClass(medicineDiv, 'gap-2');

    // Create the label for "Φάρμακο"
    const medicineLabel = this.renderer.createElement('label');
    this.renderer.setAttribute(medicineLabel, 'for', 'medicineInput');
    this.renderer.addClass(medicineLabel, 'font-semibold');
    const medicineLabelText = this.renderer.createText('Φάρμακο');
    this.renderer.appendChild(medicineLabel, medicineLabelText);

    // Create the input for "Φάρμακο"
    const medicineInput = this.renderer.createElement('input');
    this.renderer.setAttribute(medicineInput, 'pInputText', '');
    this.renderer.setAttribute(medicineInput, 'autocomplete', 'off');
    this.renderer.addClass(medicineInput, 'flex-auto');
    this.renderer.addClass(medicineInput, 'p-inputtext');

    // Append label and input to the medicineDiv
    this.renderer.appendChild(medicineDiv, medicineLabel);
    this.renderer.appendChild(medicineDiv, medicineInput);

    // Create the second div (for the "Ποσότητα" input)
    const quantityDiv = this.renderer.createElement('div');
    this.renderer.addClass(quantityDiv, 'flex');
    this.renderer.addClass(quantityDiv, 'flex-column');
    this.renderer.addClass(quantityDiv, 'gap-2');

    // Create the label for "Ποσότητα"
    const quantityLabel = this.renderer.createElement('label');
    this.renderer.setAttribute(quantityLabel, 'for', 'quantityInput');
    this.renderer.addClass(quantityLabel, 'font-semibold');
    const quantityLabelText = this.renderer.createText('Ποσότητα');
    this.renderer.appendChild(quantityLabel, quantityLabelText);

    // Create the input for "Ποσότητα"
    const quantityInput = this.renderer.createElement('input');
    this.renderer.setAttribute(quantityInput, 'pInputText', '');
    this.renderer.setAttribute(quantityInput, 'autocomplete', 'off');
    this.renderer.addClass(quantityInput, 'flex-auto');
    this.renderer.addClass(quantityInput, 'p-inputtext');

    // Append label and input to the quantityDiv
    this.renderer.appendChild(quantityDiv, quantityLabel);
    this.renderer.appendChild(quantityDiv, quantityInput);

    // Append both input divs to the new row
    this.renderer.appendChild(newRow, medicineDiv);
    this.renderer.appendChild(newRow, quantityDiv);

    // Append the new row to the parent container
    this.renderer.appendChild(parent, newRow);
  }

  showAddReference() {
    this.showReference = true;
  }

  formatDate(date: any): string {
    // Split the input date string into day, month, and year
    const [day, month, year] = date.split('/');

    // Return the date in the desired yyyy/mm/dd format
    return `${day}`;
  }

  formatTime(timeStr: string): string {
    // Extract the hours and minutes part from the string
    let [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    // Convert to 24-hour format if needed
    if (period === 'PM' && hours < 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Format the hours and minutes with leading zeros
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine into the desired format
    return `${formattedHours}:${formattedMinutes}:00`;
  }
}
