import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import {
  AppLayoutComponent,
  LoginComponent,
  NotfoundComponent,
  RegisterComponent,
  AuthGuard,
  InboxComponent,
} from 'shared';
import { ManagePatientsComponent } from './pages/manage-patients/manage-patients.component';
import { PatientDetailsComponent } from './pages/manage-patients/patient-details/patient-details.component';
import { PrescriptionReferralComponent } from './pages/prescription-referral/prescription-referral.component';
import { ManageVisitsComponent } from './pages/manage-visits/manage-visits.component';
import { PrescriptionDetailsComponent } from './pages/prescription-referral/prescription-details/prescription-details.component';
import { CoverageComponent } from './pages/coverage/coverage.component';
import { VisitFormComponent } from './pages/visit-form/visit-form.component';
import { DoctorDetailsComponent } from './pages/visit-form/doctor-details/doctor-details.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              data: { breadcrumb: 'Πίνακας Ελέγχου' },
              component: DashboardComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'calendar',
              data: { breadcrumb: 'Ημερολόγιο​' },
              component: CalendarComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'manage-patients',
              data: { breadcrumb: 'Ασθενείς' },
              component: ManagePatientsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'patient-details/:id',
              data: { breadcrumb: '' },
              component: PatientDetailsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'manage-visits',
              data: { breadcrumb: 'Περιστατικά' },
              component: ManageVisitsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'coverage',
              data: { breadcrumb: 'Ασφαλιστική Κάλυψη' },
              component: CoverageComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'prescription-details/:id',
              data: { breadcrumb: '' },
              component: PrescriptionDetailsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'prescriptions',
              data: { breadcrumb: 'Συνταγογράφηση / Παραπεμπτικό' },
              component: PrescriptionReferralComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'doctors',
              data: { breadcrumb: 'Βάση Ιατρών' },
              component: VisitFormComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'doctor-details/:id',
              data: { breadcrumb: 'Πληροφορίες Ιατρού' },
              component: DoctorDetailsComponent,
              canActivate: [AuthGuard],
            },
          ],
        },
        { path: 'notfound', component: NotfoundComponent },
        { path: 'messages', component: InboxComponent },
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
