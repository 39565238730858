import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { MemberService, MenuDataService } from 'shared';
import { AuthService } from '../../../shared/src/lib/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  model: any = [
    {
      label: '',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Πίνακας Ελέγχου',
          icon: 'pi pi-fw pi-home',
          routerLink: [''],
        },
        {
          label: 'Ημερολόγιο',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/calendar'],
        },
        // {
        //   label: 'Ασθενείς',
        //   icon: 'pi pi-fw pi-users',
        //   routerLink: ['/manage-patients'],

        // },
        {
          label: 'Ασθενείς',
          icon: 'pi pi-fw pi-users',
          items: [
            {
              label: 'Λίστα',
              icon: 'pi pi-fw pi-list',
              routerLink: ['/manage-patients'],
            },
            {
              label: 'Ενδονοσοκομειακοί​',
              icon: 'pi pi-fw pi-building',
              routerLink: ['/manage-patients'],
            },
            {
              label: 'Εξωνοσοκομειακοί​',
              icon: 'pi pi-fw pi-external-link',
              routerLink: ['/manage-patients'],
            },
          ],
        },
        {
          label: 'Περιστατικά',
          icon: 'pi pi-fw pi-clipboard',
          items: [
            {
              label: 'Λίστα Περιστατικών',
              icon: 'pi pi-fw pi-list',
              routerLink: ['/manage-visits'],
            },
            {
              label: 'Νοσηλεία',
              icon: 'pi pi-fw pi-building',
              routerLink: ['/manage-visits'],
            },
            {
              label: 'Εξωτερικά Ιατρεία',
              icon: 'pi pi-fw pi-external-link',
              routerLink: ['/manage-visits'],
            },
          ],
        },
        {
          label: 'Ασφαλιστική Κάλυψη',
          icon: 'pi pi-fw pi-wallet',
          routerLink: ['/coverage'],
        },
        {
          label: 'Συνταγές/Παραπεμπτικά',
          icon: 'pi pi-fw pi-heart',
          routerLink: ['/prescriptions'],
        },
        {
          label: 'Βάση Ιατρών',
          icon: 'pi pi-fw pi-wave-pulse',
          routerLink: ['/doctors'],
        },

        {
          label: 'Ρυθμίσεις',
          icon: 'pi pi-fw pi-cog',
          routerLink: ['***'],
        },
      ],
    },
  ];

  constructor(
    private primengConfig: PrimeNGConfig,
    private menuDataService: MenuDataService,
    private memberService: MemberService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.menuDataService.update(this.model);
    this.memberService.appName = 'medicalCenter';
    if (localStorage.getItem('token')) {
      const user = JSON.parse(localStorage.getItem('token')!);
      this.memberService
        .getEntityByUsername(user.userName)
        .subscribe((user) => {
          this.authService.currentUserSubject.next(user);
        });
    }
  }
}
