import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';

import { ImportsModule } from 'shared';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';




@Component({
  selector: 'app-patient-details',
  standalone: true,
  imports: [
    ImportsModule,
    TabViewModule,
    FileUploadModule,
    ToastModule,
    ListboxModule,
    FormsModule,
    CommonModule,
    InputTextModule,
    CheckboxModule,
  ],
  templateUrl: './prescription-details.component.html',
  styleUrl: './prescription-details.component.scss',
})
export class PrescriptionDetailsComponent implements OnInit {
  userId: number = 0;
  referral: any = {};
  patient: any = { name: 'Γεώργιος Παπαδόπουλος', amka: '12345678901' };
  doctor: any = { name: 'Δρ. Ιωάννης Κωνσταντινίδης', specialty: 'Γενικός Ιατρός' };

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));
    if (isNaN(this.userId)) {
      console.error('Invalid userId:', this.userId);
      return;
    }

    // Simulate loading referral details
    this.loadReferralDetails();
  }

  loadReferralDetails(): void {
    // Replace this with actual logic to load referral details based on userId
    this.referral = {
      referralNumber: '12345',
      issueDate: new Date('2024-08-01'),
      expiryDate: new Date('2024-12-01'),
      reason: 'Πόνος στην κοιλιακή περιοχή',
      diagnosis: 'Κοιλιοκήλη',
      tests: [
        { code: 'A001', description: 'Γενική Αίματος', quantity: 1, cost: 20.00, selected: false },
        { code: 'A002', description: 'Υπέρηχος Κοιλίας', quantity: 1, cost: 50.00, selected: false }
      ]
    };
  }

  onPartialExecution(): void {
    const selectedTests = this.referral.tests.filter((test: { selected: any; }) => test.selected);
    console.log('Selected tests for partial execution:', selectedTests);
    console.log('Partial execution clicked');
  }

  onFullExecution(): void {
    console.log('Full execution clicked');
  }
}
  
//   diagnoses: any = [
//     {
//       icd10: 'A00',
//       diagnosis: 'Χολέρα',
//       comment: 'Χωρίς πρόσφατο ιστορικό ταξιδιού',
//       event: 'Ξέσπασμα',
//       startDate: new Date('2024-01-10'),
//       outcome: 'Ανάρρωση',
//       endDate: new Date('2024-01-20'),
//     },
//     {
//       icd10: 'B20',
//       diagnosis: 'Νόσος του HIV',
//       comment: 'Υπό θεραπεία ART',
//       event: 'Προγραμματισμένος έλεγχος',
//       startDate: new Date('2023-12-05'),
//       outcome: 'Σταθερή',
//       endDate: new Date('2024-01-15'),
//     },
//     // Add more data as needed
//   ];
//   records = [
//     {
//       date: new Date('2024-08-20'),
//       title: 'Εξετάσεις αίματος',
//       category: 'Δια ζώσης',
//       reason: 'Ρουτίνα',
//       notes: 'Αναμονή αποτελεσμάτων',
//     },
//     {
//       date: new Date('2024-07-15'),
//       title: 'Συνάντηση με γιατρό',
//       category: 'Βιντεοκλήση',
//       reason: 'Ανασκόπηση φαρμακευτικής αγωγής',
//       notes: 'Συνεχίστε με την ίδια αγωγή',
//     },
//     {
//       date: new Date('2024-06-10'),
//       title: 'Διαγνωστικές εξετάσεις',
//       category: 'Δια ζώσης',
//       reason: 'Συμπτώματα άσθματος',
//       notes: 'Χρειάζεται περαιτέρω αξιολόγηση',
//     },
//     {
//       date: new Date('2024-05-22'),
//       title: 'Ψυχιατρική συνεδρία',
//       category: 'Βιντεοκλήση',
//       reason: 'Παρακολούθηση',
//       notes: 'Αναφορά βελτίωσης',
//     },
//   ];
//   examinations = [
//     {
//       date: new Date('2024-08-20'),
//       category: 'Αιματολογικές',
//       examination: 'Ολική Αιματολογική Εξέταση',
//       result: 'Κανονικά επίπεδα',
//     },
//     {
//       date: new Date('2024-07-15'),
//       category: 'Ακτινολογικές',
//       examination: 'Ακτινογραφία Θώρακος',
//       result: 'Χωρίς ευρήματα',
//     },
//     {
//       date: new Date('2024-06-10'),
//       category: 'Βιοχημικές',
//       examination: 'Εξέταση Γλυκόζης',
//       result: 'Ελαφρώς αυξημένη',
//     },
//     {
//       date: new Date('2024-05-22'),
//       category: 'Ουρολογικές',
//       examination: 'Ανάλυση Ούρων',
//       result: 'Χωρίς ανωμαλίες',
//     },
//   ];

//   clinicalExams = [
//     {
//       date: new Date('2024-08-25'),
//       examination: 'Κλινική εξέταση καρδιάς',
//       notes: 'Κανονικός καρδιακός ρυθμός, χωρίς ανωμαλίες',
//     },
//     {
//       date: new Date('2024-07-10'),
//       examination: 'Εξέταση πνευμόνων',
//       notes: 'Φυσιολογικοί ήχοι αναπνοής, χωρίς συριγμό',
//     },
//     {
//       date: new Date('2024-06-18'),
//       examination: 'Εξέταση αρθρώσεων',
//       notes: 'Ήπιο οίδημα στο αριστερό γόνατο, προτείνεται παρακολούθηση',
//     },
//     {
//       date: new Date('2024-05-30'),
//       examination: 'Νευρολογική εξέταση',
//       notes: 'Φυσιολογικά αντανακλαστικά, χωρίς νευρολογικές ανωμαλίες',
//     },
//   ];

//   files: any = [
//     {
//       fileName: 'Patient_Record_01.pdf',
//       dateOfUpload: new Date('2024-08-01'),
//       fileSize: '1.5 MB',
//     },
//     {
//       fileName: 'XRay_Image_02.png',
//       dateOfUpload: new Date('2024-07-28'),
//       fileSize: '2.3 MB',
//     },
//     {
//       fileName: 'Blood_Test_Report_03.docx',
//       dateOfUpload: new Date('2024-07-15'),
//       fileSize: '250 KB',
//     },
//     {
//       fileName: 'MRI_Scan_04.jpg',
//       dateOfUpload: new Date('2024-06-30'),
//       fileSize: '5.6 MB',
//     },
//     {
//       fileName: 'Prescription_05.pdf',
//       dateOfUpload: new Date('2024-05-22'),
//       fileSize: '800 KB',
//     },
//   ];

//   exams: any;
//   selectedExams: any;
//   textAreaValue: string = '';
//   modalMessage: any;
//   insured: any;

//   selectedDateForAppointment: any;

//   subscriptions: Subscription[] = [];
//   name: any;
//   uploadedFiles: any[] = [];

//   showPrescription: boolean = false;
//   showReference: boolean = false;
//   doctorName: any;
//   dataLoaded: boolean = false;
//   @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;

//   userId: number = 0;
//   constructor(
//     private route: ActivatedRoute,
//     private insuredService: InsuredService,
//     private renderer: Renderer2,
//     private el: ElementRef
//   ) {}
//   ngOnInit(): void {
//     this.userId = Number(this.route.snapshot.paramMap.get('id'));
//     this.doctorName = JSON.parse(localStorage.getItem('token')!);

//     this.doctorName.userName = this.doctorName.userName
//       .split('_') // Split into ["rachel", "stark"]
//       .map((namePart: any) => {
//         return namePart.charAt(0).toUpperCase() + namePart.slice(1);
//       }) // Capitalize each part
//       .join(' ');

//     const insuredSubscription$ = this.insuredService.getInsuredByMemberId(
//       this.userId
//     );
//     const examTypeSubscription$ = this.insuredService.getExamTypes();
//     const subscriptions$ = forkJoin([
//       insuredSubscription$,
//       examTypeSubscription$,
//     ]).subscribe({
//       next: ([insuredResult, examTypeResult]) => {
//         if (insuredResult) {
//           this.insured = insuredResult.details.profile;
//         }

//         if (examTypeResult) {
//           this.exams = examTypeResult.examTypes.map(
//             (exam: { name: any; examTypeId: any }) => {
//               return {
//                 name: exam.name,
//                 code: exam.examTypeId,
//               };
//             }
//           );
//         }
//       },
//       error: (error) => {
//         console.error('Error fetching data:', error);
//       },
//       complete: () => {
//         this.dataLoaded = true;
//       },
//     });

//     this.subscriptions.push(subscriptions$);
//   }
//   ngOnDestroy(): void {
//     this.subscriptions.pop();
//   }

//   onUpload(event: any) {
//     for (let file of event.files) {
//       this.uploadedFiles.push(file);
//     }

//     // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully' });
//   }

//   onImageMouseOver(file: any) {
//     this.buttonEl.toArray().forEach((el) => {
//       el.nativeElement.id === file.name
//         ? (el.nativeElement.style.display = 'flex')
//         : null;
//     });
//   }

//   onImageMouseLeave(file: any) {
//     this.buttonEl.toArray().forEach((el) => {
//       el.nativeElement.id === file.name
//         ? (el.nativeElement.style.display = 'none')
//         : null;
//     });
//   }

//   removeImage(event: Event, file: any) {
//     event.stopPropagation();
//     this.uploadedFiles = this.uploadedFiles.filter((i) => i !== file);
//   }

//   showAddPrescription() {
//     this.showPrescription = true;
//   }

//   formatDate(date: any): string {
//     // Split the input date string into day, month, and year
//     const [day, month, year] = date.split('/');

//     // Return the date in the desired yyyy/mm/dd format
//     return `${day}`;
//   }

//   formatTime(timeStr: string): string {
//     // Extract the hours and minutes part from the string
//     let [time, period] = timeStr.split(' ');
//     let [hours, minutes] = time.split(':').map(Number);

//     // Convert to 24-hour format if needed
//     if (period === 'PM' && hours < 12) {
//       hours += 12;
//     }
//     if (period === 'AM' && hours === 12) {
//       hours = 0;
//     }

//     // Format the hours and minutes with leading zeros
//     let formattedHours = hours.toString().padStart(2, '0');
//     let formattedMinutes = minutes.toString().padStart(2, '0');

//     // Combine into the desired format
//     return `${formattedHours}:${formattedMinutes}:00`;
//   }

//   addReference() {
//     if (
//       (this.selectedDateForAppointment != null ||
//         this.selectedDateForAppointment != '') &&
//       (!this.selectedExams || this.selectedExams.length > 0) &&
//       this.textAreaValue != null
//     ) {
//       let reference: any = {
//         userId: this.userId,
//         requestUserId: 44,
//         startDate: this.formatDate(this.selectedDateForAppointment),
//         expiryDate: '2024-12-02',
//         examTypeId: this.selectedExams.map((item: any) => {
//           return item.code;
//         }),
//         notes: this.textAreaValue,
//       };
//       const createExamSubscription$ = this.insuredService
//         .createExams(reference)
//         .subscribe({
//           next: (res) => {
//             this.modalMessage = this.formValidator(res);
//           },
//           error: (err) => {
//             alert("Κάτι πήγε στραβά!")
//             console.error(err);
//           },
//           complete: () => {
//             /*checks if the validation message from the api is null,
//              which means no validation errors and closes the modal */
//             if (this.modalMessage == null) {
//               this.showReference = false;
//               alert("Καταχωρήθηκε Επιτυχώς!")
//             }
//           },
//         });

//       this.subscriptions.push(createExamSubscription$);
//     }
//   }
//   formValidator(message: any) {
//     return message.validationMessages.map((m: any) => {
//       return m;
//     });
//   }

//   addMedicine() {
//     // Reference the parent container where the new row will be added
//     const parent = this.el.nativeElement.querySelector('#medicines');

//     // Create the new row container
//     const newRow = this.renderer.createElement('div');
//     this.renderer.addClass(newRow, 'flex');
//     this.renderer.addClass(newRow, 'gap-5');
//     this.renderer.addClass(newRow, 'align-items-center'); // Align items vertically centered

//     // Create the first div (for the "Φάρμακο" input)
//     const medicineDiv = this.renderer.createElement('div');
//     this.renderer.addClass(medicineDiv, 'flex');
//     this.renderer.addClass(medicineDiv, 'flex-column');
//     this.renderer.addClass(medicineDiv, 'gap-2');

//     // Create the label for "Φάρμακο"
//     const medicineLabel = this.renderer.createElement('label');
//     this.renderer.setAttribute(medicineLabel, 'for', 'medicineInput');
//     this.renderer.addClass(medicineLabel, 'font-semibold');
//     const medicineLabelText = this.renderer.createText('Φάρμακο');
//     this.renderer.appendChild(medicineLabel, medicineLabelText);

//     // Create the input for "Φάρμακο"
//     const medicineInput = this.renderer.createElement('input');
//     this.renderer.setAttribute(medicineInput, 'pInputText', '');
//     this.renderer.setAttribute(medicineInput, 'autocomplete', 'off');
//     this.renderer.addClass(medicineInput, 'flex-auto');
//     this.renderer.addClass(medicineInput, 'p-inputtext');

//     // Append label and input to the medicineDiv
//     this.renderer.appendChild(medicineDiv, medicineLabel);
//     this.renderer.appendChild(medicineDiv, medicineInput);

//     // Create the second div (for the "Ποσότητα" input)
//     const quantityDiv = this.renderer.createElement('div');
//     this.renderer.addClass(quantityDiv, 'flex');
//     this.renderer.addClass(quantityDiv, 'flex-column');
//     this.renderer.addClass(quantityDiv, 'gap-2');

//     // Create the label for "Ποσότητα"
//     const quantityLabel = this.renderer.createElement('label');
//     this.renderer.setAttribute(quantityLabel, 'for', 'quantityInput');
//     this.renderer.addClass(quantityLabel, 'font-semibold');
//     const quantityLabelText = this.renderer.createText('Ποσότητα');
//     this.renderer.appendChild(quantityLabel, quantityLabelText);

//     // Create the input for "Ποσότητα"
//     const quantityInput = this.renderer.createElement('input');
//     this.renderer.setAttribute(quantityInput, 'pInputText', '');
//     this.renderer.setAttribute(quantityInput, 'autocomplete', 'off');
//     this.renderer.addClass(quantityInput, 'flex-auto');
//     this.renderer.addClass(quantityInput, 'p-inputtext');

//     // Append label and input to the quantityDiv
//     this.renderer.appendChild(quantityDiv, quantityLabel);
//     this.renderer.appendChild(quantityDiv, quantityInput);

//     // Append both input divs to the new row
//     this.renderer.appendChild(newRow, medicineDiv);
//     this.renderer.appendChild(newRow, quantityDiv);

//     // Append the new row to the parent container
//     this.renderer.appendChild(parent, newRow);
//   }

//   showAddReference() {
//     this.showReference = true;
//   }
// }
