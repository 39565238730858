import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // Import dayGrid plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // import TimeGridPlugin
import interactionPlugin from '@fullcalendar/interaction'; // Import interaction plugin if needed
import { CalendarOptions } from '@fullcalendar/core';
import {
  Appointment,
  AppointmentsService,
  ExamCentersService,
  ExamsService,
  HospitalDataService,
} from 'shared';
import { FullCalendarModule } from '@fullcalendar/angular';

import { forkJoin, Subscription } from 'rxjs';
import { TabViewModule } from 'primeng/tabview';
import { Table, TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { TagModule } from 'primeng/tag';
import { Menu, MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  standalone: true,
  imports: [
    CurrencyPipe,
    CommonModule,
    FormsModule,
    TableModule,
    CalendarModule,
    TabViewModule,
    DialogModule,
    FullCalendarModule,
    MenubarModule,
    DropdownModule,
    TagModule,
    MenuModule,
    MultiSelectModule,
    InputTextareaModule
  ],
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  tags: any;

  showDialog: boolean = false;
  clickedEvent: any = null;
  view: string = '';
  calendarOptions: any = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: false,
  };

  changedEvent: any;
  patient_history: any[] = [];
  pendingAppointments: any[] = [];
  subscriptions: Subscription[] = [];

  appointments: any;
  @ViewChild('dt')
  dt!: Table;
  selectedDate: any;

  showAppointment: boolean = false;
  appointmentData!: Appointment;

  items: MenuItem[] = [];
  selectedMenuItem: string = 'appointments';
  doctors: any = [
    { name: 'Όλοι οι ιατροί', id: 0 },
    { name: 'Γεώργιος Παπαδόπουλος', id: 1 },
    { name: 'Ελένη Σαμαρά', id: 2 },
    { name: 'Νίκος Δημητρίου', id: 3 },
    { name: 'Άννα Καραγιάννη', id: 4 },
    { name: 'Μιχάλης Κωνσταντίνου', id: 5 },
    { name: 'Ευαγγελία Μπογδάνο', id: 6 },
  ];
  selectedDoctor: any;

  clonedAppointments: any;
  statuses = [
    { label: 'Αποδεκτό', value: 'Αποδεκτό' },
    { label: 'Απόρριψη', value: 'Απόρριψη' },
    { label: 'Εκκρεμεί', value: 'Εκκρεμεί' },
    { label: 'Ακυρωμένο', value: 'Ακυρωμένο' },
    { label: 'Ολοκληρωμένο', value: 'Ολοκληρωμένο' },
  ];

  clinicId: number = 0;
  successVisible: boolean = false;
  successDeleteVisible: boolean = false;
  editAppointmentDialog: boolean = false;
  errorVisible: boolean = false;
  selectedAppointment: any = null;
  availableDay: any;
  availableTimes: any;
  selectedTime: any;
  @ViewChild('menu') menu!: Menu;
  
  constructor(
    private appointmentService: AppointmentsService,
    private HospitalDataService: HospitalDataService,
    private examService: ExamsService,
    private medicalCenterService: ExamCentersService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.items = [
      {
        label: 'Ημερολόγιο',
        icon: '',
        command: () => this.selectMenuItem('calendar'),
        styleClass:
          this.selectedMenuItem === 'calendar' ? 'active-menu-item' : '',
      },
      {
        label: 'Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('appointments'),
        styleClass:
          this.selectedMenuItem === 'appointments' ? 'active-menu-item' : '',
      },
      {
        label: 'Αιτήματα Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('pendingAppointments'),
        styleClass:
          this.selectedMenuItem === 'pendingAppointments'
            ? 'active-menu-item'
            : '',
      },
      {
        label: 'Διαθεσιμότητα',
        icon: '',
        command: () => this.selectMenuItem('availability'),
        styleClass:
          this.selectedMenuItem === 'availability' ? 'active-menu-item' : '',
      },
    ];
    if (this.doctors.length > 0) {
      this.selectedDoctor = this.doctors[0];
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.pop();
  }
  fetchData() {
    let currentUserData = JSON.parse(localStorage.getItem('token')!);

    let appointmentSubscription$;
    let subscriptions$;
    const memberServiceSubscription$ =
      this.HospitalDataService.getClinicDataByUsername(
        currentUserData.userName
      ).subscribe((clinic) => {
        console.log(clinic);
        this.clinicId = clinic.medicalCenter.userId;
        appointmentSubscription$ = this.appointmentService.getAppointments(
          clinic.medicalCenter.userId
        );
        subscriptions$ = forkJoin([appointmentSubscription$]).subscribe({
          next: ([appointmentResult]) => {
            if (appointmentResult) {
              console.log(appointmentResult);
              this.patient_history = appointmentResult.data;
              this.appointments = appointmentResult.data.filter((item: any) => {
                return item.appointmentStatus;
                // return item.appointmentStatus == 'Approved';
              });
              this.clonedAppointments = this.appointments;
              this.appointments.sort((a: any, b: any) => {
                const dateA = new Date(a.appointmentDate).getTime();
                const dateB = new Date(b.appointmentDate).getTime();
                return dateB - dateA;
              });
              console.log('apps', this.appointments);
              this.pendingAppointments = appointmentResult.data.filter(
                (item: any) => {
                  return item.appointmentStatus == 'Pending';
                }
              );
              this.getCalendarOptions(this.patient_history);
            }
          },
          error(err) {
            console.error('Error fetching data', err);
          },
          complete: () => {},
        });
        this.subscriptions.push(subscriptions$);
      });
    this.subscriptions.push(memberServiceSubscription$);
  }

  selectMenuItem(item: any): void {
    this.selectedMenuItem = item;
    // Update the styleClass for each menu item based on selection
    this.items = this.items.map((menuItem) => ({
      ...menuItem,
      styleClass:
        menuItem.label === this.getLabelByItemKey(item)
          ? 'active-menu-item'
          : '',
    }));
  }

  // Helper method to get the label based on the selected key
  getLabelByItemKey(key: string): string {
    const map: { [key: string]: string } = {
      calendar: 'Ημερολόγιο',
      appointments: 'Ραντεβού',
      pendingAppointments: 'Αιτήματα Ραντεβού',
      availability: 'Διαθεσιμότητα',
    };
    return map[key] || '';
  }

  onRowEditInit(appointment: any) {
    this.clonedAppointments[appointment.appointmentId] = { ...appointment };
  }

  onRowEditSave(appointment: any) {
    console.log(appointment);
    if (appointment.appointmentId > 0) {
      delete this.clonedAppointments[appointment.appointmentId];

      let appointmentStatus = {
        appointmentStatusId: -1,
      };
      if (appointment.appointmentStatus == 'Αποδεκτό') {
        appointmentStatus.appointmentStatusId = 1;
      } else if (appointment.appointmentStatus == 'Απόρριψη') {
        appointmentStatus.appointmentStatusId = 2;
      } else if (appointment.appointmentStatus == 'Εκκρεμεί') {
        appointmentStatus.appointmentStatusId = 3;
      } else if (appointment.appointmentStatus == 'Ακυρωμένο') {
        appointmentStatus.appointmentStatusId = 4;
      } else {
        appointmentStatus.appointmentStatusId = 5;
      }

      const approveAppointmentService$ = this.appointmentService
        .updateAppointmentStatusById(
          appointment.appointmentId,
          appointmentStatus
        )
        .subscribe({
          next: (res) => {
            console.log('change res', res);
            // let otherArray = this.appointments.concat([appointment]);
            // this.appointments = otherArray;
            // this.pendingAppointments = this.pendingAppointments.filter(
            //   (app) => app.appointmentId != appointment.appointmentId
            // );
            // alert('Το ραντεβού αποδέχθηκε επιτυχώς!');
            // this.cd.detectChanges();
          },
          error: (error: any) => {
            alert('Υπήρξε Κάποιο Πρόβλημα. Προσπαθήστε Αργότερα');
            console.error('Error approving appointment:', error);
          },
          complete: () => {
            this.cd.detectChanges();
          },
        });
      this.subscriptions.push(approveAppointmentService$);
    }
  }

  onRowEditCancel(appointment: any, index: number) {
    this.appointments[index] =
      this.clonedAppointments[appointment.appointmentId];
    delete this.clonedAppointments[appointment.appointmentId];
  }

  reject(appointment: any) {
    let appointmentStatus = {
      appointmentStatusId: 2,
    };
    const appointmentService$ = this.appointmentService
      .updateAppointmentStatusById(appointment.appointmentId, appointmentStatus)
      .subscribe({
        next: (res) => {
          appointment.appointmentStatus = 'Απορρίφθηκε';
          this.pendingAppointments = this.pendingAppointments.filter(
            (app) => app.appointmentId != appointment.appointmentId
          );
          this.successDeleteVisible = true;
          this.cd.detectChanges();
        },
        error: (error: any) => {
          this.errorVisible = true;
          console.error('Error approving appointment:', error);
        },
        complete: () => {
          this.cd.detectChanges();
        },
      });
    this.subscriptions.push(appointmentService$);
  }

  approve(appointment: any) {
    let appointmentStatus = {
      appointmentStatusId: 1,
    };
    const approveAppointmentService$ = this.appointmentService
      .updateAppointmentStatusById(appointment.appointmentId, appointmentStatus)
      .subscribe({
        next: (res) => {
          const examSubscription$ = this.medicalCenterService
            .selfAssignExamToClinic(appointment.examId, this.clinicId)
            .subscribe({
              next: (res) => {
                console.log('assigned to hospital', res);
              },
              error: (err) => {
                console.error(err);
              },
            });
          this.subscriptions.push(examSubscription$);
          appointment.appointmentStatus = 'Approved';

          this.pendingAppointments = this.pendingAppointments.filter(
            (app) => app.appointmentId != appointment.appointmentId
          );
          alert('Το ραντεβού αποδέχθηκε επιτυχώς!');
          this.cd.detectChanges();
        },
        error: (error: any) => {
          alert('Υπήρξε Κάποιο Πρόβλημα. Προσπαθήστε Αργότερα');
          console.error('Error approving appointment:', error);
        },
        complete: () => {
          this.cd.detectChanges();
        },
      });
    this.subscriptions.push(approveAppointmentService$);
  }
  getCalendarOptions(data: any) {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin], // Ensure plugins are included
      height: 720,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: (e: MouseEvent) => this.onEventClick(e),
      select: (e: MouseEvent) => this.onDateSelect(e),

      events: data.map((appointment: any) => {
        if (appointment.appointmentStatus == 'Approved') {
          return {
            title: appointment.reasonOfVisit,
            start: `${appointment.appointmentDate}T${appointment.appointmentTime}`,
            color: '#007bff',
          };
        } else if (appointment.appointmentStatus == 'Completed') {
          return {
            title: appointment.reasonOfVisit,
            start: `${appointment.appointmentDate}T${appointment.appointmentTime}`,
            color: '#28a745',
          };
        } else if (appointment.appointmentStatus == 'Pending') {
          return {
            title: appointment.reasonOfVisit,
            start: `${appointment.appointmentDate}T${appointment.appointmentTime}`,
            color: '#FFA500',
          };
        } else {
          return {
            title: appointment.reasonOfVisit,
            start: `${appointment.appointmentDate}T${appointment.appointmentTime}`,
            color: '#dc3545',
          };
        }
      }),
    };
  }

  showAppointmentDetails(appointment: any) {
    this.showAppointment = true;
    this.appointmentData = appointment;
  }

  handleSave() {}
  onEditClick() {}
  onEventClick(e: any) {
    this.clickedEvent = e.event;
    let plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true,
    });
    this.view = 'display';
    this.showDialog = true;

    this.changedEvent = { ...plainEvent, ...this.clickedEvent };
    this.changedEvent.start = this.clickedEvent.start;
    this.changedEvent.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start;
  }

  onDateSelect(e: any) {
    this.view = 'new';
    this.showDialog = true;
    this.changedEvent = {
      ...e,
      title: null,
      description: null,
      location: null,
      backgroundColor: null,
      borderColor: null,
      textColor: null,
      tag: { color: null, name: null },
    };
  }

  filter(table: Table, event: Date) {
    console.log(
      event.toLocaleDateString('en-GB'),
      ' ',
      this.appointments[0].appointmentDate
    );
    // console.log((event.target as HTMLInputElement).value)
    this.dt.filterGlobal(event.toLocaleDateString('en-GB'), 'contains');
  }

  getSeverity(status: any) {
    switch (status) {
      case 'Αποδεκτό':
        return 'info';
      case 'Απόρριψη':
        return 'danger';
      case 'Εκκρεμεί':
        return 'warning';
      case 'Ακυρωμένο':
        return 'danger';
      case 'Ολοκληρωμένο':
        return 'success';

      case 'Pending':
        return 'warning';
      case 'Approved':
        return 'info';
      case 'Completed':
        return 'success';

      case 1:
        return 'info';
      case 2:
        return 'danger';
      case 3:
        return 'warning';
      case 4:
        return 'danger';
      case 5:
        return 'success';
      default:
        return 'danger';
    }
  }

  getExamStringValue(num: number) {
    switch (num) {
      case 1:
        return 'Υπό-Επεξεργασία';
      case 2:
        return 'Ακυρωμένο';
      case 3:
        return 'Εκκρεμεί';
      case 4:
        return 'Ακυρωμένο';
      case 5:
        return 'Ολοκληρωμένο';
      default:
        return 'N/A';
    }
  }

  filterByDate(dt2: Table) {
    if (this.selectedDate) {
      const formattedDate = this.formatDate(this.selectedDate);
      dt2.filter(formattedDate, 'appointmentDate', 'equals');
    }
  }

  // Helper function to format the date to match the 'appointmentDate' format
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Method to reset the filter and show all rows
  resetFilter(dt2: Table) {
    this.selectedDate = null;  
    dt2.clear();  
  }

  // Method to handle date change and reset the filter when cleared
  onDateChange(event: any, dt2: Table) {
    if (event === null || event === '') {
      this.resetFilter(dt2);  
    } else {
      this.filterByDate(dt2);  
    }
  }

  onMenuButtonClick(event: MouseEvent, appointment: Appointment) {
    event.preventDefault(); 
    this.selectedAppointment = appointment; 
    this.cd.detectChanges(); 
    this.ngZone.runOutsideAngular(() => {
      this.menu.toggle(event); 
    });
  }

  menuItems(appointment: any): MenuItem[] {
    return [
      {
        label: 'Επεξεργασία',
        icon: 'pi pi-fw pi-pencil',
        command: () => this.editAppointment(appointment)
      },
      {
        label: 'Απόρριψη',
        icon: 'pi pi-fw pi-times',
        command: () => this.reject(appointment)
      }
    ];
  }

  editAppointment(appointment: any) {
    this.editAppointmentDialog = true;
    this.appointmentData = appointment;
    this.cd.detectChanges();
    console.log(this.appointmentData);
  }
}
