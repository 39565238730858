import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImportsModule, HospitalDataService } from 'shared';
import { SidebarModule } from 'primeng/sidebar';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-manage-patients',
  standalone: true,
  imports: [ImportsModule, SidebarModule],
  templateUrl: './manage-patients.component.html',
  styleUrl: './manage-patients.component.scss',
})
export class ManagePatientsComponent implements OnInit, OnDestroy {
  insured: any;
  sidebarVisible: boolean = false;
  statuses!: any[];
  value: any;
  representatives: any;

  subscriptions: Subscription[] = [];
  item: any;
  visible: boolean = false;
  dataLoaded: boolean = false;
  constructor(private medicalCenterService: HospitalDataService) {}
  ngOnInit(): void {
    this.medicalCenterService.getClinicPatientsByUserId(90).subscribe({
      next: (result) => {
        if (result) {
          console.log(result);
          this.insured = result.patients;
          this.item = this.insured[0];
          // Mark data as loaded
          this.dataLoaded = true;
        }
      },
    });
  }
  ngOnDestroy() {
    this.subscriptions.pop();
  }

  viewDetails(item: any) {
    this.item = item;
    this.visible = true;
  }

  toggleSidebar() {
    this.sidebarVisible = true;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }
}
