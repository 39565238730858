import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Appointment,
  AppointmentsService,
  AppointmentsTableComponent,
  CustomCalendarComponent,
  ExamCentersService,
  PendingAppointmentsComponent,
} from 'shared';
import { FullCalendarModule } from '@fullcalendar/angular';

import { Subscription, switchMap } from 'rxjs';
import { TabViewModule } from 'primeng/tabview';
import { Table, TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { TagModule } from 'primeng/tag';
import { Menu, MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    CalendarModule,
    TabViewModule,
    DialogModule,
    MenubarModule,
    DropdownModule,
    TagModule,
    MenuModule,
    MultiSelectModule,
    InputTextareaModule,
    CustomCalendarComponent,
    AppointmentsTableComponent,
    PendingAppointmentsComponent,
  ],
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  tableHeaders = [
    { field: 'appointmentId', header: 'Κωδ. Επίσκεψης' },
    { field: 'fullName', header: 'Επισκέπτης' },
    { field: 'socialSecurityNumber', header: 'ΑΜΚΑ' },
    { field: 'appointmentDate', header: 'Ημερομηνία' },
    { field: 'appointmentTime', header: 'Ώρα' },
    { field: 'examId', header: 'Κωδ. Παραπεμπτικού' },
    { field: 'examStatusId', header: 'Εκτελέστηκε' },
  ];

  patient_history: any[] = [];
  pendingAppointments: any[] = [];
  subscriptions: Subscription[] = [];

  appointments: any;
  @ViewChild('dt')
  dt!: Table;
  selectedDate: any;

  showAppointment: boolean = false;
  appointmentData!: Appointment;

  items: MenuItem[] = [];
  selectedMenuItem: string = 'appointments';
  doctors: any = [
    { name: 'Όλοι οι ιατροί', id: 0 },
    { name: 'Γεώργιος Παπαδόπουλος', id: 1 },
    { name: 'Ελένη Σαμαρά', id: 2 },
    { name: 'Νίκος Δημητρίου', id: 3 },
    { name: 'Άννα Καραγιάννη', id: 4 },
    { name: 'Μιχάλης Κωνσταντίνου', id: 5 },
    { name: 'Ευαγγελία Μπογδάνο', id: 6 },
  ];
  selectedDoctor: any;

  informationModal: boolean = false;
  getInformationModalContent: string = '';
  getInformationModalHeaderContent: string = '';
  clinicId: number = 0;
  notes: string = 'Επείγον';
  successVisible: boolean = false;
  successDeleteVisible: boolean = false;
  editAppointmentDialog: boolean = false;
  errorVisible: boolean = false;
  selectedAppointment: any = null;
  availableDay: any;
  availableTimes: any;
  selectedTime: any;
  @ViewChild('menu') menu!: Menu;

  currentDate = new Date();

  constructor(
    private appointmentService: AppointmentsService,
    private medicalCenterDataService: ExamCentersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment == 'pendingappointments') {
        this.selectedMenuItem = 'pendingAppointments';
      }
    });

    this.fetchData();

    this.items = [
      {
        label: 'Ημερολόγιο',
        icon: '',
        command: () => this.selectMenuItem('calendar'),
        styleClass:
          this.selectedMenuItem === 'calendar' ? 'active-menu-item' : '',
      },
      {
        label: 'Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('appointments'),
        styleClass:
          this.selectedMenuItem === 'appointments' ? 'active-menu-item' : '',
      },
      {
        label: 'Αιτήματα Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('pendingAppointments'),
        styleClass:
          this.selectedMenuItem === 'pendingAppointments'
            ? 'active-menu-item'
            : '',
      },
    ];
    if (this.doctors.length > 0) {
      this.selectedDoctor = this.doctors[0];
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.pop();
  }
  fetchData() {
    const currentUserData = JSON.parse(localStorage.getItem('token')!);

    const clinicDataSubscription$ = this.medicalCenterDataService
      .getMedicalCenterDataByUsername(currentUserData.userName)
      .pipe(
        switchMap((clinic: any) => {
          this.clinicId = clinic.medicalCenter.userId;
          return this.appointmentService.getAppointments(this.clinicId);
        })
      )
      .subscribe({
        next: (appointmentResult) =>
          this.processAppointments(appointmentResult),
        error: (err) => console.error('Error fetching data:', err),
      });

    this.subscriptions.push(clinicDataSubscription$);
  }

  private processAppointments(appointmentResult: any): void {
    if (!appointmentResult) return;

    this.appointments = this.sortAppointmentsByDate(appointmentResult.data);

    this.appointments.forEach((appointment: any) => {
      appointment.appointmentStatus = this.getAppointmentStatus(appointment);
    });
    this.pendingAppointments = this.filterPendingAppointments(
      appointmentResult.data
    );
  }

  private sortAppointmentsByDate(appointments: any[]): any[] {
    return appointments.sort((a, b) => {
      const dateA = new Date(a.appointmentDate).getTime();
      const dateB = new Date(b.appointmentDate).getTime();
      return dateB - dateA;
    });
  }

  private filterPendingAppointments(appointments: any[]): any[] {
    return appointments.filter(
      (item) =>
        item.appointmentStatus.appointmentStatusId === 3 ||
        item.appointmentStatus.name === 'Εκκρεμεί'
    );
  }
  private getAppointmentStatus(appointment: any): any {
    const appDate = new Date(appointment.appointmentDate);
    if (
      this.currentDate > appDate &&
      (appointment.appointmentStatus.appointmentStatusId == 3 ||
        appointment.appointmentStatus.appointmentStatusId == 1)
    ) {
      appointment.appointmentStatus = {
        appointmentStatusId: 2,
        name: 'Ακυρωμένο',
      };
    }
    return appointment.appointmentStatus;
  }
  getPendingAppointments(event: any) {
    this.appointments = this.appointments.map((app: any) => {
      if (app.appointmentId === event.appointmentId) {
        return event;
      }
      return app;
    });
  }

  selectMenuItem(item: any): void {
    this.selectedMenuItem = item;
    this.items = this.items.map((menuItem) => ({
      ...menuItem,
      styleClass:
        menuItem.label === this.getLabelByItemKey(item)
          ? 'active-menu-item'
          : '',
    }));
  }

  getLabelByItemKey(key: string): string {
    const map: { [key: string]: string } = {
      calendar: 'Ημερολόγιο',
      appointments: 'Ραντεβού',
      pendingAppointments: 'Αιτήματα Ραντεβού',
      availability: 'Διαθεσιμότητα',
    };
    return map[key] || '';
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
