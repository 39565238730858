import { Component, OnInit, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService, MemberService, MenuDataService } from 'shared';
import { RouterOutlet } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private primengConfig = inject(PrimeNGConfig);
  private menuDataService = inject(MenuDataService);
  private memberService = inject(MemberService);
  private authService = inject(AuthService);

  model: any = [
    {
      label: '',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Πίνακας Ελέγχου',
          icon: 'pi pi-fw pi-home',
          routerLink: [''],
        },
        {
          label: 'Ημερολόγιο',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/calendar'],
        },
        {
          label: 'Ασθενείς',
          icon: 'pi pi-fw pi-users',
          routerLink: ['/manage-patients'],
        },
        {
          label: 'Περιστατικά',
          icon: 'pi pi-fw pi-clipboard',
          routerLink: ['/manage-visits'],
        },
        {
          label: 'Ασφαλιστική Κάλυψη',
          icon: 'pi pi-fw pi-wallet',
          routerLink: ['/coverage'],
        },
        {
          label: 'Συνταγές/Παραπεμπτικά',
          icon: 'pi pi-fw pi-heart',
          routerLink: ['/prescriptions'],
        },
        {
          label: 'Βάση Ιατρών',
          icon: 'pi pi-fw pi-wave-pulse',
          routerLink: ['/doctors'],
        },

        {
          label: 'Ρυθμίσεις',
          icon: 'pi pi-fw pi-cog',
          routerLink: ['***'],
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.menuDataService.update(this.model);
    this.memberService.appName = 'medicalCenter';
    const user = JSON.parse(localStorage.getItem('token')!);
    if (user) {
      this.authService.fetchUser(user).subscribe(() => {});
    }
  }
}
