import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


import {
  AppLayoutComponent,
  LoginComponent,
  NotfoundComponent,
  RegisterComponent,
  AuthGuard,
  InboxComponent,
} from 'shared';









@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              data: { breadcrumb: 'Πίνακας Ελέγχου' },
              loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'calendar',
              data: { breadcrumb: 'Ημερολόγιο​' },
              loadComponent: () => import('./pages/calendar/calendar.component').then(m => m.CalendarComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'manage-patients',
              data: { breadcrumb: 'Ασθενείς' },
              loadComponent: () => import('./pages/manage-patients/manage-patients.component').then(m => m.ManagePatientsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'patient-details/:id',
              data: { breadcrumb: '' },
              loadComponent: () => import('./pages/manage-patients/patient-details/patient-details.component').then(m => m.PatientDetailsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'manage-visits',
              data: { breadcrumb: 'Περιστατικά' },
              loadComponent: () => import('./pages/manage-visits/manage-visits.component').then(m => m.ManageVisitsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'coverage',
              data: { breadcrumb: 'Ασφαλιστική Κάλυψη' },
              loadComponent: () => import('./pages/coverage/coverage.component').then(m => m.CoverageComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'prescription-details/:id',
              data: { breadcrumb: '' },
              loadComponent: () => import('./pages/prescription-referral/prescription-details/prescription-details.component').then(m => m.PrescriptionDetailsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'prescriptions',
              data: { breadcrumb: 'Συνταγογράφηση / Παραπεμπτικό' },
              loadComponent: () => import('./pages/prescription-referral/prescription-referral.component').then(m => m.PrescriptionReferralComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'doctors',
              data: { breadcrumb: 'Βάση Ιατρών' },
              loadComponent: () => import('./pages/visit-form/visit-form.component').then(m => m.VisitFormComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'doctor-details/:id',
              data: { breadcrumb: 'Πληροφορίες Ιατρού' },
              loadComponent: () => import('./pages/visit-form/doctor-details/doctor-details.component').then(m => m.DoctorDetailsComponent),
              canActivate: [AuthGuard],
            },
          ],
        },
        { path: 'notfound', component: NotfoundComponent },
        { path: 'messages', component: InboxComponent },
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
