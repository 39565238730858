@if(dataLoaded){
<div class="grid row-gap-5">
  <div class="xl:col-12 lg:col-12 col-12">
    <div class="card">
      <div class="grid">
        <div
          class="col-12 md:col-4 xl:col-4 flex justify-content-center align-items-center"
        >
          <img
            src="https://gravatar.com/avatar/cf86990164dbd7ef3d0f95e81cafbe1e?s=400&d=mp&r=x"
            class="border-round-xl"
            style="height: 150px; width: 150px"
          />
        </div>
        <div class="col-12 md:col-8 xl:col-8">
          <div class="flex flex-column gap-3">
            <span class="font-bold text-xl">{{ doctor.fullName }}</span>
            <span
              >{{ doctor.specialty.name }} &nbsp;&nbsp; {{ doctor.area }}</span
            >

            <div class="flex flex-row gap-4">
              <i class="pi pi-phone text-primary font-bold"></i>
              <span class="text-primary font-bold">Επικοινώνησε</span>
            </div>
            @for (address of doctor.addresses; track $index) {
            <div class="flex flex-row gap-4">
              <i class="pi pi-map-marker text-color font-bold"></i>
              <span class="font-medium">{{ address.fullAddress }}</span>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-tabView>
  <p-tabPanel header="Περιγραφή" class="">
    <div class="card flex flex-column mb-3">
      <div class="flex flex-row gap-3 align-items-center">
        <!-- <i class="pi pi-heart text-xl"></i> -->
        <!-- <span class="text-xl font-semibold">Περιγραφή</span> -->
      </div>
      <ng-container *ngIf="doctor">
        <div class="flex flex-wrap">
          <div class="flex flex-column gap-2 w-full">
            <span class="text-lg font-medium"
              >Ο {{ doctor.fullName }} είναι {{ doctor.specialty.name }} με
              χρόνια εμπειρίας στην ιατρική πρακτική και αφοσιωμένος στη
              φροντίδα των ασθενών του. Ειδικεύεται στη θεραπεία αθλητικών
              τραυματισμών και παθήσεων που σχετίζονται με την κίνηση. Έχει
              ολοκληρώσει τις σπουδές του στη Σχολή Ιατρικής του Πανεπιστημίου
              Αθηνών και συνέχισε με μεταπτυχιακές σπουδές στην Αθλητιατρική.
              Επίσης, έχει συμμετάσχει σε πλήθος σεμιναρίων και συνεδρίων,
              προσπαθώντας πάντα να είναι ενημερωμένος για τις τελευταίες
              εξελίξεις στον τομέα του. Παρά τις προκλήσεις της ειδικότητάς του,
              η αφοσίωσή του και η συνεχής προσπάθεια για βελτίωση τον καθιστούν
              μια αξιόλογη επιλογή για κάθε αθλητή ή ασθενή που αναζητά
              εξειδικευμένη φροντίδα.
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </p-tabPanel>

  <p-tabPanel header="Υπηρεσίες">
    <div class="card flex flex-column">
      <div class="flex flex-row gap-3 align-items-center">
        <!-- <i class="pi pi-heart text-xl"></i> -->
        <!-- <span class="text-xl font-semibold">Υπηρεσίες</span> -->
      </div>
      <ng-container *ngIf="services[0]?.services">
        <div
          class="flex flex-wrap"
          *ngFor="let service of services[0].services"
        >
          <div class="flex flex-column gap-2 w-full">
            <span class="text-lg font-bold">{{ service.service }}</span>
            <span class="text-lg font-bold text-primary">{{
              service.price
            }}</span>
            <p-divider></p-divider>
          </div>
        </div>
      </ng-container>
    </div>
  </p-tabPanel>

  <p-tabPanel header="Τοποθεσία">
    <div class="card flex flex-column">
      <div class="flex flex-row gap-3 align-items-center">
        <!-- <i class="pi pi-map-marker text-xl"></i> -->
        <!-- <span class="text-xl font-semibold">Τοποθεσία</span> -->
      </div>
      <span class="text-medium font-semibold">{{
        doctor.addresses[1]?.fullAddress
      }}</span>
      <div style="height: 500px">
        <aol-map>
          <aol-view [zoom]="12">
            <aol-coordinate
              [x]="23.72332"
              [y]="37.98854"
              [srid]="'EPSG:4326'"
            ></aol-coordinate>
          </aol-view>
          <aol-layer-tile>
            <aol-source-osm></aol-source-osm>
          </aol-layer-tile>

          <!-- Vector layer for the marker -->
          <aol-layer-vector>
            <aol-source-vector>
              <aol-feature>
                <aol-geometry-point>
                  <aol-coordinate
                    [x]="23.72332"
                    [y]="37.98854"
                    [srid]="'EPSG:4326'"
                  ></aol-coordinate>
                </aol-geometry-point>
                <aol-style>
                  <aol-style-icon
                    [src]="
                      'https://openlayers.org/en/latest/examples/data/icon.png'
                    "
                    [scale]="1"
                  ></aol-style-icon>
                </aol-style>
              </aol-feature>
            </aol-source-vector>
          </aol-layer-vector>
          <aol-layer-vector>
            <aol-source-vector>
              <aol-feature>
                <aol-geometry-point>
                  <aol-coordinate
                    [x]="23.77072"
                    [y]="37.97512"
                    [srid]="'EPSG:4326'"
                  ></aol-coordinate>
                </aol-geometry-point>
                <aol-style>
                  <aol-style-icon
                    [src]="
                      'https://openlayers.org/en/latest/examples/data/icon.png'
                    "
                    [scale]="1"
                  ></aol-style-icon>
                </aol-style>
              </aol-feature>
            </aol-source-vector>
          </aol-layer-vector>
          <aol-overlay>
            <aol-coordinate [x]="23.72332" [y]="37.98854" [srid]="'EPSG:4326'">
            </aol-coordinate>
            <aol-content>
              <div class="my-overlay-class">
                <h6>Οδός Κωνσταντίνου Παλαιολόγου 22, Αθήνα, Ελλάδα</h6>
              </div>
            </aol-content>
          </aol-overlay>
          <aol-interaction-default></aol-interaction-default>
          <aol-control-scaleline></aol-control-scaleline>
          <aol-control-zoomslider></aol-control-zoomslider>
          <aol-control-zoom></aol-control-zoom>
        </aol-map>
      </div>
    </div>
  </p-tabPanel>

  <!-- <p-tabPanel header="Βιογραφικό">
    <div class="card flex flex-column">
      <div class="flex flex-row gap-3 align-items-center">
      </div>
      <div class="flex flex-wrap mb-3">
        <div class="flex flex-column gap-1 w-full">
          <span class="text-xl font-bold text-wrap mb-2">Σπουδές</span>
          <ng-container *ngFor="let educ of education?.education">
            <span class="text-lg font-bold ml-3"
              >{{ educ.degree }}, {{ educ.institution }}</span
            >
            <span class="text-lg text-wrap mb-3 ml-6">{{ educ.field }}</span>
          </ng-container>

          <span class="text-xl font-bold text-wrap mt-5 mb-2"
            >Επαγγελματική εμπειρία</span
          >
          <ng-container *ngFor="let exp of education?.professionalExperience">
            <span class="text-lg font-bold ml-3"
              >{{ exp.role }}, {{ exp.location }}</span
            >
            <span class="text-lg text-wrap mb-3 ml-6">{{
              exp.description
            }}</span>
          </ng-container>

          <span class="text-xl font-bold text-wrap mt-5 mb-2">Συνέδρια</span>
          <ng-container *ngFor="let conf of education?.conferences">
            <span class="text-lg font-medium ml-3 mb-1"
              >{{ conf.date }}: {{ conf.title }} - {{ conf.event }}</span
            >
          </ng-container>
        </div>
      </div>
    </div>
  </p-tabPanel> -->
</p-tabView>

}@else {
<div
  class="col-12 flex justify-content-center align-items-center h-100em w-100em"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

}
