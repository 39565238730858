@if(dataLoaded){
<div class="grid">
  <div class="col-12 grid">
    <div class="lg:col-4 md:col-4 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 h-full"
      >
        <span class="font-bolder text-xl mt-3">Φάκελος Υγείας</span>
        <i class="pi pi-sign-in lg:text-6xl text-2xl font-bold"></i>
        <div
          class="pButton bg-primary-700 text-white font-bold border-round-bottom-3xl p-1 text-xl"
        >
          Αίτημα Πρόσβασης
        </div>
      </div>
    </div>
    <div class="lg:col-4 md:col-4 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 h-full"
      >
        <span class="font-bolder text-xl mt-3">Συνταγογραφήσεις</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          3 | number
        }}</span>
        <div
          class="pButton flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1 cursor-pointer"
          (click)="showAddPrescription()"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Συνταγογράφηση
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
    <div class="lg:col-4 md:col-4 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 h-full"
      >
        <span class="font-bolder text-xl mt-3">Παραπομπές</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          5 | number
        }}</span>
        <div
          class="flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1 cursor-pointer"
          (click)="showAddReference()"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Παραπομπή
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
  </div>
  <!-- Prescription Modal -->
  <p-dialog
    header="Header"
    [(visible)]="showPrescription"
    [modal]="true"
    [style]="{ width: '50rem' }"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-center gap-2">
        <span class="font-bold"></span>
      </div>
    </ng-template>
    <div class="flex align-items-center justify-content-center gap-2">
      <span class="font-bold text-xl">Συνταγογράφηση</span>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="patient" class="font-semibold w-6rem"> Ασθενής </label>
      <input pInputText id="patient" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="doctor" class="font-semibold w-6rem"> Ιατρός </label>
      <input pInputText id="doctor" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex justify-content-between">
      <div class="flex flex-column gap-2 mb-3">
        <label for="prescriptionDate" class="font-semibold w-6rem">
          Ημ.Συνταγής
        </label>
        <input
          pInputText
          id="prescriptionDate"
          class="flex-auto"
          autocomplete="off"
        />
      </div>
      <div class="flex flex-column gap-2 mb-3">
        <label for="prescriptionExpirationDate" class="font-semibold">
          Ημ.Λήξης Συνταγής
        </label>
        <input
          pInputText
          id="prescriptionExpirationDate"
          disabled
          placeholder="Σημείωση: 2 μήνες μέτα θα λήγει"
          class="flex-auto"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="flex flex-column gap-4 mb-3">
      <label for="prescriptionDate" class="font-semibold">
        Σύνθεση Συνταγής
      </label>
    </div>
    <div id="medicines" class="flex flex-column gap-3 mb-3">
      <div class="flex gap-5">
        <div class="flex flex-column gap-2">
          <label for="prescriptionExpirationDate" class="font-semibold">
            Φάρμακο
          </label>
          <input
            pInputText
            id="prescriptionExpirationDate"
            class="flex-auto"
            autocomplete="off"
          />
        </div>
        <div class="flex flex-column gap-2">
          <label for="quantity" class="font-semibold"> Ποσότητα </label>
          <input
            pInputText
            id="prescriptionExpirationDate"
            class="flex-auto"
            autocomplete="off"
          />
        </div>
        <div
          pButton
          class="pi pi-plus align-self-end"
          (click)="addMedicine()"
        ></div>
      </div>
    </div>
    <div class="w-full flex flex-column gap-3">
      <label for="comments" class="font-semibold"> Σημειώσεις </label>
      <textarea
        rows="5"
        cols="30"
        placeholder="Your Message"
        pInputTextarea
      ></textarea>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        label="Ακύρωση"
        [text]="true"
        severity="secondary"
        (onClick)="showPrescription = false"
      />
      <p-button
        label="Υποβολή"
        [outlined]="true"
        severity="secondary"
        (onClick)="showPrescription = false"
      />
    </ng-template>
  </p-dialog>
  <!-- Reference Modal -->
  <p-dialog
    header="Header"
    [(visible)]="showReference"
    [modal]="true"
    [style]="{ width: '50rem' }"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-center gap-2">
        <span class="font-bold"></span>
      </div>
    </ng-template>
    <div class="flex align-items-center justify-content-center gap-2 m-3">
      <span class="font-bold text-xl">Παραπομπή</span>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="patient" class="font-semibold w-6rem"> Ασθενής </label>
      <input
        pInputText
        id="patient"
        class="flex-auto"
        autocomplete="off"
        [value]="insured.firstName + ' ' + insured.lastName"
        disabled
      />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="doctor" class="font-semibold w-6rem"> Ιατρός </label>
      <input
        pInputText
        id="doctor"
        class="flex-auto"
        autocomplete="off"
        [value]="clinicName.userName"
        disabled
      />
    </div>

    <div class="flex flex-column gap-2 mb-3">
      <label for="prescriptionExpirationDate" class="font-semibold">
        Επιλογή Εξέτασης
      </label>
      <p-multiSelect
        [options]="exams"
        [(ngModel)]="selectedExams"
        placeholder="Επιλέξτε Εξετάσεις"
        optionLabel="name"
        scrollHeight="250px"
        [showClear]="true"
        display="chip"
      >
        <ng-template let-medicine pTemplate="item">
          <span class="ml-2">{{ medicine.name }}</span>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="prescriptionDate" class="font-semibold w-6rem">
        Ημ.Έξετασης
      </label>
      <input
        type="date"
        pInputText
        name="selectedDate"
        [(ngModel)]="selectedDateForAppointment"
      />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="prescriptionExpirationDate" class="font-semibold">
        Ημ.Λήξης Έξετασης
      </label>
      <input
        pInputText
        id="prescriptionExpirationDate"
        disabled
        placeholder="Σημείωση: 2 μήνες μέτα θα λήγει"
        class="flex-auto"
        autocomplete="off"
      />
    </div>

    <div class="w-full flex flex-column gap-3">
      <label for="comments" class="font-semibold"> Σημειώσεις </label>
      <textarea
        rows="5"
        cols="30"
        [(ngModel)]="textAreaValue"
        pInputTextarea
      ></textarea>
    </div>
    @if(modalMessage){
    <div class="w-full flex flex-column gap-3 mt-3">
      @for(message of modalMessage; track message){
      <span class="text-red-400 text-lg font-medium">{{ message }}</span>
      }
    </div>
    }
    <ng-template pTemplate="footer">
      <p-button
        label="Ακύρωση"
        [text]="true"
        severity="secondary"
        (onClick)="showReference = false"
      />
      <p-button
        label="Υποβολή"
        [outlined]="true"
        severity="secondary"
        (click)="addReference()"
      />
    </ng-template>
  </p-dialog>
  <div class="col-12">
    <div class="card">
      <p-tabView class="mt-3" [scrollable]="true">
        <p-tabPanel header="Προφίλ">
          <h6 class="my-3 text-primary">Προσωπικά Στοιχεία​</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Όνομα</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.firstName!"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Επώνυμο</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.lastName!"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Ημ.Γεννησης</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.dateOfBirth| date:'dd/MM/yyyy'"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Φύλο</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.gender"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Εθνικότητα</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.nationality"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2"
                  >Οικογενειακή Κατάσταση</label
                >
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.maritalStatus"
                />
              </div>
            </div>
          </div>
          <h6 class="my-3 text-primary">Αναγνωριστικά​</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">A.M.K.A</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.socialSecurityNumber"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Α.Φ.Μ</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.taxIdentificationNumber"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Α.Δ.Τ</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.identityCardNumber"
                />
              </div>
            </div>
          </div>
          <h6 class="my-3 text-primary">Στοιχεία​ Επικοινωνίας</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Κινητό 1</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.mobile"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Σταθερό</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.telephone"
                />
              </div>

              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Email</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.email"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Δήμος</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.municipality"
                />
              </div>

              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Οδός, Αριθμός</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.address"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Τ.Κ.</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.zipCode"
                />
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Εξετάσεις">
          <div
            class="flex md:flex-row flex-column md:justify-content-between align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Επισκέψεις</h6>
          </div>

          <p-table [value]="examTableData">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 10rem">Παραπεμπτικό</th>
                <th>Ιατρός</th>
                <th s tyle="width:10rem">Ημ.Έναρξης</th>
                <th style="width: 10rem">Ημ.Λήξης</th>
                <th>Εξετάσεις</th>
                <th>Σημειώσεις Ιατρού</th>
                <th>Κατάσταση</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exam>
              <tr>
                <td>{{ exam.examId }}</td>
                <td>{{ exam.doctorName }}</td>
                <td>{{ exam.startDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ exam.expiryDate | date : "dd/MM/yyyy" }}</td>
                <td style="width: 15rem">
                  @for(examType of exam.examTypes; track examType){
                  <div
                    class="font-bold p-1 bg-primary-700 mb-1 text-color border-round-3xl text-center"
                  >
                    {{ examType.name }}
                  </div>
                  }
                </td>
                <td style="width: 10rem">{{ exam.doctorNotes }}</td>
                <td>{{ exam?.examStatus?.name || "" }}</td>
                <td>
                  @if(exam?.examTypes?.length>0){
                  <span
                    ><i
                      (click)="openExamDetails(exam)"
                      class="pi pi-pencil cursor-pointer"
                    ></i
                  ></span>
                  }
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-dialog
            header="Header"
            [(visible)]="showExamDetails"
            [modal]="true"
            [style]="{ width: '50rem' }"
          >
            <ng-template pTemplate="header">
              <div class="flex align-items-center justify-content-center gap-2">
                <span class="font-bold"></span>
              </div>
            </ng-template>
            <div
              class="flex align-items-center justify-content-center gap-2 m-3"
            >
              <span class="font-bold text-xl">Επεξεργασία Εξετάσεων</span>
            </div>
            @if(selectedRowExam?.examTypes?.length>0){

            <div class="flex flex-column gap-5 mb-3">
              <label for="prescriptionExpirationDate" class="font-semibold">
                Επιλογή Εξέτασης
              </label>
              @for(examType of selectedRowExam?.examTypes; track
              examType.examTypeId) {
              <form
                #examResult="ngForm"
                (ngSubmit)="onSubmitExams(examResult, examType)"
                class="p-fluid"
              >
                <div
                  class="flex flex-row justify-content-between align-items-center gap-5"
                >
                  <input
                    id="examTypeName_{{ examType.examTypeId }}"
                    type="text"
                    pInputText
                    name="examTypeName"
                    [ngModel]="examType?.name"
                    [disabled]="true"
                  />

                  <span class="p-input-icon-left">
                    <i class="pi pi-pencil"></i>
                    <input
                      id="diagnosis_{{ examType.examTypeId }}"
                      type="text"
                      name="diagnosis"
                      pInputText
                      placeholder="Διάγνωση"
                      [(ngModel)]="examType.diagnosis"
                    />
                  </span>
                  <button
                    pButton
                    pRipple
                    label="Αποθήκευση"
                    class="p-button-primary"
                    [disabled]="examType.isSubmitted"
                  ></button>
                </div>
              </form>
              }
            </div>

            } @if(modalMessage){
            <div class="w-full flex flex-column gap-3 mt-3">
              @for(message of modalMessage; track message){
              <span class="text-red-400 text-lg font-medium">{{
                message
              }}</span>
              }
            </div>
            }
            <ng-template pTemplate="footer">
              <p-button
                label="Ακύρωση"
                [text]="true"
                severity="secondary"
                (onClick)="showExamDetails = false"
              />
              <p-button
                label="Υποβολή"
                [outlined]="true"
                severity="secondary"
                (click)="showExamDetails = false"
              />
            </ng-template>
          </p-dialog>
        </p-tabPanel>
        <p-tabPanel header="Ιστορικό">
          <div
            class="flex md:flex-row flex-column md:justify-content-between align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Ιστορικό Νοσημάτων​</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Διάγνωσης"
              class="p-button-success"
            ></button> -->
          </div>
          <p-table [value]="diagnoses">
            <ng-template pTemplate="header">
              <tr>
                <th>ICD10</th>
                <th>Διάγνωση</th>
                <th>Σχόλιο</th>
                <th>Συμβάν</th>
                <th>Έναρξη</th>
                <th>Έκβαση</th>
                <th>Λήξη</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-diagnosis>
              <tr>
                <td>{{ diagnosis.icd10 }}</td>
                <td>{{ diagnosis.diagnosis }}</td>
                <td>{{ diagnosis.comment }}</td>
                <td>{{ diagnosis.event }}</td>
                <td>{{ diagnosis.startDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ diagnosis.outcome }}</td>
                <td>{{ diagnosis.endDate | date : "dd/MM/yyyy" }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
} @else{ }
