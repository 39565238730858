@if(dataLoaded){
<div class="grid">
  <!-- <div class="text-1000 font-bold text-3xl m-3">Διενέργεια Επίσκεψης</div> -->
  <!-- <div class="col-12">
    <lib-filters
      header="Αναζήτηση Ιατρού"
      [dropdownCount]="2"
      [dropdownOptions]="[areaDropdown, specialtyDropdown]"
      [placeholderOptions]="['Επιλογή Περιοχής', 'Ειδικότητα']"
      [keysToFilter]="['area','specialty[name]']"
      [(data)]="doctors"
    ></lib-filters>
  </div> -->
  @if(doctors.length==0 && dataLoaded==true){
  <div class="text-xl">No Doctors found</div>
  } @if(dataLoaded==true){ @for(doctor of doctors; track doctor){
  <div class="col-12 lg:col-6">
    <app-doctor-profile [doctorData]="doctor"></app-doctor-profile>
  </div>
  } }@else {
  <div
    class="col-12 flex justify-content-center align-items-center h-100em w-100em"
  >
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>

  }
</div>
}
