<div class="card mb-3 doctor-profile-card">
  <div class="grid">
    <div class="col-12">
      <div class="doctor-profile">
        <div class="avatar">
          <a [routerLink]="['/doctor-details/', doctorData.userId]"
            ><img
              src="https://gravatar.com/avatar/cf86990164dbd7ef3d0f95e81cafbe1e?s=400&d=mp&r=x"
              [alt]="doctorData.fullName"
          /></a>
        </div>
        <div class="doctor-details">
          <h5>
            <span class="cursor-pointer" [routerLink]="['/doctor-details/', doctorData.userId]">
              {{ doctorData.fullName }}
              <small>{{ doctorData.degrees }}</small></span
            >
          </h5>
          <div>
            <span class="cursor-pointer" [routerLink]="['/doctor-details/', doctorData.userId]">{{
              doctorData.specialty.name
            }}</span>
          </div>
          <div *ngFor="let address of doctorData.addresses">
            <i class="pi pi-map-marker"></i> {{ address.fullAddress }}
          </div>
          <!-- <div>
            <button
              type="button"
              class="p-button"
              [routerLink]="['/doctor-details/', doctorData.userId]"
            >
              Κλείσε Ραντεβού
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="col-12 md:col-6">
      <div>
        <p>
          <i class="pi pi-calendar mr-1"></i
          ><strong>Επιλογή Ημερομηνίας</strong>
        </p>
        <div class="availability-container">
          <div class="availability-grid">
            <button
              *ngFor="let slot of availabilitySlots"
              [attr.data-date-slot]="slot.dateSlot"
              [attr.data-date]="slot.date"
              class="availability-button"
            >
              <div class="font-medium rounded w-full">
                {{ slot.dateDisplay }}
              </div>
              <div class="font-medium rounded w-full">{{ slot.time }}</div>
            </button>
          </div>
          <button
            type="button"
            class="dat-button cursor-pointer"
            [routerLink]="['/doctor-details/', doctorData.userId]"
          >
            Εμφάνιση όλων
          </button>
        </div>
      </div>
    </div> -->
    <div class="col-12">
      <div class="content-container">
      <div class="experience-section mt-2">
        <i class="pi pi-info-circle"></i>
        <strong> Εμπειρία: </strong>
        <span *ngFor="let experience of experiences" class="proc">{{
          experience
        }}</span>
      </div>
      <div class="doctor-info">
        <p>
          {{ doctorData.description }}
        </p>
        <!-- <button pButton type="button" label="λιγότερα" (click)="toggleMore()" class="more-button icon-smaller" *ngIf="showMore"></button> -->
        <a
          href="javascript:void(0)"
          (click)="toggleMore()"
          class="text-primary text-sm hover:underline flex justify-content-end"
          *ngIf="showMore"
          ><strong>λιγότερα</strong></a
        >
        <p *ngIf="showMore">
          {{ doctorData.fullDescription }}
        </p>
        <!-- <button pButton type="button" label="περισσότερα" (click)="toggleMore()" class="more-button icon-smaller" *ngIf="!showMore"></button> -->
        <a
          href="javascript:void(0)"
          (click)="toggleMore()"
          class="text-primary text-sm hover:underline flex justify-content-end"
          *ngIf="!showMore"
          ><strong>περισσότερα</strong></a
        >
      </div>
    </div>
    </div>
  </div>
</div>
