<div class="grid">
  <div class="col align-items-center"></div>

  <div class="col-12 card flex justify-content-between align-items-center">
    <p-menubar [model]="items" />
    <p-dropdown
      [options]="doctors"
      [(ngModel)]="selectedDoctor"
      optionLabel="name"
      placeholder="Επιλέξτε γιατρό"
      [optionValue]="doctors[0].label"
    >
    </p-dropdown> 
  </div>
  @if(selectedMenuItem==="calendar"){
  <div class="col-12 card">
    <full-calendar [options]="calendarOptions"></full-calendar>
    <p-dialog
      #dd
      [(visible)]="showDialog"
      [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
      [style]="{ width: '36rem' }"
      [modal]="true"
      [closable]="true"
      (onHide)="view = ''"
    >
      <ng-template pTemplate="header">
        <span class="text-900 font-semibold text-xl">{{
          view === "display"
            ? changedEvent.title
            : view === "new"
            ? "New Event"
            : "Edit Event"
        }}</span>
      </ng-template>
      <ng-template pTemplate="content">
        <div *ngIf="view === 'display'">
          <span class="text-900 font-semibold block mb-2">Description</span>
          <span class="block mb-3">{{ changedEvent.description }}</span>

          <div class="grid">
            <div class="col-6">
              <div class="text-900 font-semibold mb-2">Start</div>
              <p class="flex align-items-center m-0">
                <i class="pi pi-fw pi-clock text-700 mr-2"></i>
                <span>{{ changedEvent.start.toISOString().slice(0, 10) }}</span>
              </p>
            </div>
            <div class="col-6">
              <div class="text-900 font-semibold mb-2">End</div>
              <p class="flex align-items-center m-0">
                <i class="pi pi-fw pi-clock text-700 mr-2"></i>
                <span>{{ changedEvent.end.toISOString().slice(0, 10) }}</span>
              </p>
            </div>
            <div class="col-12">
              <div class="text-900 font-semibold mb-2">Location</div>
              <p class="flex align-items-center m-0">
                <i class="pi pi-fw pi-clock text-700 mr-2"></i>
                <span>{{ changedEvent.location }}</span>
              </p>
            </div>
            <div class="col-12">
              <div class="text-900 font-semibold mb-2">Color</div>
              <p class="flex align-items-center m-0">
                <span
                  class="inline-flex flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                  [style.backgroundColor]="changedEvent.tag.color"
                ></span>
                <span>{{ changedEvent.tag.name }}</span>
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="view !== 'display'">
          <div class="grid p-fluid formgrid">
            <div class="col-12 md:col-6 field">
              <label for="title" class="text-900 font-semibold">Title</label>
              <span class="p-input-icon-left">
                <i class="pi pi-pencil"></i>
                <input
                  id="title"
                  type="text"
                  pInputText
                  placeholder="Title"
                  [(ngModel)]="changedEvent.title"
                />
              </span>
            </div>
            <div class="col-12 md:col-6 field">
              <label for="location" class="text-900 font-semibold"
                >Location</label
              >
              <span class="p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <input
                  id="location"
                  type="text"
                  pInputText
                  placeholder="Location"
                  [(ngModel)]="changedEvent.location"
                />
              </span>
            </div>
            <div class="col-12 field">
              <label for="description" class="text-900 font-semibold"
                >Event Description</label
              >
              <textarea
                id="description"
                type="text"
                pInputTextarea
                [rows]="5"
                [(ngModel)]="changedEvent.description"
                style="resize: none"
              ></textarea>
            </div>

            <!-- <div class="col-12 md:col-6 field">
                      <label for="start" class="text-900 font-semibold">Start Date</label>
                      <p-calendar [appendTo]="dd" dateFormat="mm-dd-yy" [maxDate]="changedEvent.end" [showTime]="true" [required]="true" inputId="start" [(ngModel)]="changedEvent.start"></p-calendar>
                  </div>
                  <div class="col-12 md:col-6 field">
                      <label for="start" class="text-900 font-semibold">End Date</label>
                      <p-calendar [appendTo]="dd" dateFormat="mm-dd-yy" [minDate]="changedEvent.start" [showTime]="true" [required]="true" inputId="end" [(ngModel)]="changedEvent.end"></p-calendar>
                  </div> -->
            <div class="col-12 field">
              <label for="company-color" class="text-900 font-semibold"
                >Color</label
              >
              <p-dropdown
                inputId="company-color"
                [appendTo]="dd"
                [options]="tags"
                [(ngModel)]="changedEvent.tag"
                optionLabel="name"
              >
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="changedEvent.tag" class="flex align-items-center">
                    <div
                      class="flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                      [style.backgroundColor]="changedEvent.tag.color"
                    ></div>
                    <div>{{ changedEvent.tag.name }}</div>
                  </div>
                </ng-template>
                <ng-template let-tag pTemplate="item">
                  <div class="flex align-items-center">
                    <div
                      class="flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                      [style.backgroundColor]="tag.color"
                    ></div>
                    <div>{{ tag.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button
          pButton
          *ngIf="view === 'display'"
          label="Edit"
          icon="pi pi-pencil"
          (click)="onEditClick()"
        ></button>
        <button
          pButton
          *ngIf="view === 'new' || view === 'edit'"
          label="Save"
          icon="pi pi-check"
          (click)="handleSave()"
          [disabled]="!changedEvent.start || !changedEvent.end"
        ></button>
      </ng-template>
    </p-dialog>
  </div>
  }@else if(selectedMenuItem==="appointments"){
  <div class="col-12 card">
    <p-table
      #dt2
      [value]="appointments"
      [paginator]="true"
      [rows]="10"
      dataKey="appointmentId"
      editMode="row"
      [tableStyle]="{ 'min-width': '75rem' }"
      [globalFilterFields]="['appointmentDate']"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-start">
          <p-calendar
            [(ngModel)]="selectedDate"
            [showIcon]="true"
            dateFormat="yy-mm-dd"
            (ngModelChange)="onDateChange($event, dt2)"
            [showButtonBar]="true"
          ></p-calendar>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Κωδ. Επίσκεψης</th>
          <th>Επισκέπτης</th>
          <th>ΑΜΚΑ</th>
          <th>Ημερομηνία</th>
          <th>Ώρα</th>
          <th>Κωδ. Παραπεμπτικού</th>
          <th>Εκτελέστηκε</th>
          <th>Κατάσταση</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-visit
        let-editing="editing"
        let-ri="rowIndex"
      >
        <tr [pEditableRow]="visit">
          <td>{{ visit.appointmentId }}</td>
          <td>{{ visit.fullName }}</td>
          <td>{{ visit.socialSecurityNumber }}</td>
          <td>{{ visit.appointmentDate | date : "dd/MM/yyyy" }}</td>
          <td>{{ visit.appointmentTime }}</td>
          <td>{{ visit.examId }}</td>
          <td>
            @if(visit.examStatusId){
              <p-tag
                [value]="getExamStringValue(visit.examStatusId)"
                [severity]="getSeverity(visit.examStatusId)"
              />}
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [options]="statuses"
                  appendTo="body"
                  [(ngModel)]="visit.appointmentStatus.name"
                  [style]="{ width: '100%' }"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <p-tag
                  [value]="visit.appointmentStatus.name"
                  [severity]="getSeverity(visit.appointmentStatus.appointmentStatusId)"
                />
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- @if(visit.appointmentStatus!='Completed'){ -->
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                pButton
                pRipple
                [disabled]="visit.appointmentStatus=='Completed' ? true: false "
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="onRowEditInit(visit)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onRowEditSave(visit)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onRowEditCancel(visit, ri)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
          </td>
          <!-- } -->
        </tr>
      </ng-template>
    </p-table>
  </div>

  } @else if(selectedMenuItem==="pendingAppointments"){
  @if(pendingAppointments.length>0){
  <div class="col-12 card">
    <div class="grid row-gap-4">
      @for(appointment of pendingAppointments; track appointment){
      <div class="xl:col-3 md:col-6 sm:col-6 col-12">
        <div class="card surface-100">
          <div
            class="card-header flex-column flex-wrap justify-content-around gap-1"
          >
            <div
              class="flex flex-row align-items-center justify-content-between w-full"
            >
              <img
                [src]="appointment.avatarUrl"
                class="border-round-xl"
                style="height: 50px; width: 50px"
              />
              <i
                class="pi pi-ellipsis-v font-bold text-2xl cursor-pointer"
                (click)="showAppointmentDetails(appointment)"
              ></i>
            </div>
            <div class="flex flex-column justify-content-center gap-1">
              <span>{{ appointment.fullName }}</span>
              <span>{{ appointment.appointmentDate | date }}</span>
              <div
                class="flex xl:flex-row flex-column xl:justify-content-between gap-2"
              >
                <span class="font-bold text-sm">{{ "Απλή Επίσκεψη" }}</span>
                
              </div>
            </div>
            <div
              class="flex flex-row flex-wrap align-items-center justify-content-around xl:justify-content-around gap-2 mt-3"
            >
              <!-- <button
                pButton
                class="p-button-danger"
                (click)="reject(appointment)"
              >
                Απόρριψη
              </button> -->
              <p-menu 
                  #menu 
                  [popup]="true" 
                  [model]="menuItems(selectedAppointment)" 
                  [appendTo]="'body'"
                  [style]="{'z-index': '1000'}" 
              ></p-menu>
              <button 
                  type="button" 
                  pButton 
                  icon="pi pi-chevron-down" 
                  label="Επεξεργασία" 
                  (click)="onMenuButtonClick($event, appointment)" 
                  style="width: auto">
              </button>
              <button
                pButton
                class="p-button-success"
                (click)="approve(appointment)"
              >
                Αποδοχή
              </button>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    @if(appointmentData){
    <p-dialog
      [(visible)]="showAppointment"
      [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
      [style]="{ width: '75rem' }"
      [modal]="true"
      [closable]="true"
      (onHide)="view = ''"
    >
      <span class="font-bold text-2xl mb-5">{{
        appointmentData.fullName
      }}</span>
      <div class="mt-3 p-fluid">
        <div class="p-formgrid grid">
          <div class="field lg:col-4 md:col-6 col-12">
            <label class="font-bold" htmlFor="name2">Όνομα</label>
            <input
              pInputText
              id="name2"
              type="text"
              disabled
              [value]="appointmentData.fullName.split(' ')[0]"
            />
          </div>
          <div class="field lg:col-4 md:col-6 col-12">
            <label class="font-bold" htmlFor="email2">Επώνυμο</label>
            <input
              pInputText
              id="email2"
              type="text"
              disabled
              [value]="appointmentData.fullName.split(' ')[1]"
            />
          </div>
          <div class="field lg:col-4 md:col-6 col-12">
            <label class="font-bold" htmlFor="email2">Τηλέφωνο</label>
            <input
              pInputText
              id="email2"
              type="text"
              disabled
              [value]="appointmentData.mobile"
            />
          </div>
          <div class="field lg:col-4 md:col-6 col-12">
            <label class="font-bold" htmlFor="name2">Email</label>
            <input
              pInputText
              id="name2"
              type="text"
              disabled
              [value]="appointmentData.email"
            />
          </div>
          <div class="field lg:col-4 md:col-6 col-12">
            <label class="font-bold" htmlFor="email2">Α.Δ.Τ</label>
            <input pInputText id="email2" type="text" disabled [value]="" />
          </div>
          <div class="field lg:col-4 md:col-6 col-12">
            <label class="font-bold" htmlFor="email2">Α.Μ.Κ.Α</label>
            <input
              pInputText
              id="email2"
              type="text"
              disabled
              [value]="appointmentData.socialSecurityNumber"
            />
          </div>
          <div class="field col-12">
            <label class="font-bold" htmlFor="email2">Ιατρός</label>
            <input pInputText id="email2" type="text" disabled [value]="" />
          </div>
        </div>
      </div> </p-dialog
    >}
  </div>
  }@else {
  <div class="w-full h-full text-center text-3xl">No appointments found</div>
  } }
</div>
<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    Ευχαριστούμε για την υποβολή του αιτήματος. Το αίτημα σας θα επεξεργαστεί
    και θα ενημερωθείτε σύντομα για την εξέλιξη του.
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successDeleteVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    Το ραντεβού απορρίφθηκε.
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successDeleteVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Σφάλμα"
  [(visible)]="errorVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε αργότερα. Αν το πρόβλημα παραμένει,
    επικοινωνήστε με την υποστήριξη.
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="errorVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Προτεινόμενη Ημερομηνία"
  [(visible)]="editAppointmentDialog"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
  [focusOnShow]="false"
>
  @if(appointmentData){
  <form #appointmentForm="ngForm" class="p-fluid">
    <div class="p-field mb-4">
      <label for="fullname">Ονοματεπώνυμο Ασθενή:</label>
      <input
        id="fullname"
        type="text"
        pInputText
        name="name"
        [ngModel]="appointmentData.fullName"
        [disabled]="true"
      />
      <div *ngIf="appointmentForm.submitted" class="p-error">
        Παρακαλώ συμπληρώστε Ονοματεπώνυμο.
      </div>
    </div>

    <div class="p-field mb-4">
      <label for="selectedDate">Επιλεγμένη Ημερομηνία:</label>
      <input
        id="PreselectedDate"
        type="text"
        pInputText
        name="PreselectedDate"
        [ngModel]="appointmentData.appointmentDate | date : 'dd/MM/yyyy'"
        [disabled]="true"
      />
    </div>

    <div class="p-field mb-4">
      <label for="selectedTime">Επιλεγμένη Ώρα:</label>
      <input
        id="PreselectedTime"
        type="text"
        pInputText
        name="PreselectedTime"
        [ngModel]="appointmentData.appointmentTime"
        [disabled]="true"
      />
    </div>

    <div class="p-field mb-4">
      <label for="selectedDate">Επιλέξτε Προτεινόμενη Ημερομηνία:</label>

      <p-calendar
        name="selectedDate"
        id="selectedDate"
        [(ngModel)]="availableDay"
        [inline]="false"
        dateFormat="dd/mm/yy"
        placeholder="30/8/2026"
        [iconDisplay]="'input'"
        [showIcon]="true"
        selectionMode="single"
        [readonlyInput]="true"
        required
        appendTo="body"
        readonly
      >
      </p-calendar>
    </div>
    <div class="p-field mb-4">
      <label for="selectedTime">Επιλέξτε Προτεινόμενη Ώρα:</label>
      <p-multiSelect
        id="selectedTime"
        [options]="availableTimes"
        [(ngModel)]="selectedTime"
        placeholder=""
        optionLabel="name"
        name="selectedTime"
        placeholder="19:00"
        display="chip"
        [showClear]="true"
        appendTo="body"
      />
    </div>
    <div class="p-field mb-4">
      <label for="notes">Σημειώσεις:</label>
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        [autoResize]="false"
      ></textarea>
    </div>
  </form>
  }
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="editAppointmentDialog = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
    <button
      pButton
      [disabled]="false"
      type="submit"
      label="Αίτημα"
      class="p-button submit-btn"
    ></button>
  </ng-template>
</p-dialog>