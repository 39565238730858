import { Component, Input, OnInit } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImportsModule } from 'shared';

@Component({
  selector: 'app-dashboard-charts',
  standalone: true,
  imports: [ImportsModule, NgApexchartsModule],
  templateUrl: './dashboard-charts.component.html',
  styleUrl: './dashboard-charts.component.scss',
})
export class DashboardChartsComponent implements OnInit {
  chartOptions: any;
  @Input() chartCase: string = '';

  primaryColor = '#5297FF';
  grayColor = '#6c757d'; // Use a standard gray color

  ngOnInit() {
    this.getChartOptions(this.chartCase);
  }
  getChartOptions(chartCase: string) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    
    switch (chartCase) {
      case 'top10':
        this.chartOptions = {
          series: [
            {
              name: 'Αριθμός Εξετάσεων',
              data: [45, 32, 28, 20, 15], // Replace with your actual data
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            },
          },
          colors: ['#00E396', '#33CC99', '#66B2FF', '#3399FF', '#008FFB'],
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [
              'Παθολογικό (PATH)',
              'Χημειοθεραπεία (CHM)',
              'Χειρουργικό μιας ημέρας (ODS)',
              'Χειρουργικό (SURG)',
              'Γαστρο/Κολονοσκόπηση (G/C)',
            ],
          },
          title: {
            text: 'Top 10 KEN',
            align: 'left',
          },
          tooltip: {
            enabled: true,
            theme: 'dark',
          },
        };

        break;
      case 'line':
        this.chartOptions = {
          series: [
            {
              name: 'Περιστατικά',
              data: [250, 190, 320, 290, 210, 190, 300, 230, 230], // Replace with your actual data
            },
          ],
          chart: {
            type: 'area',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            colors: ['#008FFB'],
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100],
              colorStops: [
                {
                  offset: 0,
                  color: '#008FFB', // Starting color
                  opacity: 0.2,
                },
                {
                  offset: 100,
                  color: '#008FFB', // Ending color
                  opacity: 0.2,
                },
              ],
            },
          },
          xaxis: {
            categories: [
              '2023-01-31',
              '2023-02-29',
              '2023-03-31',
              '2023-04-30',
              '2023-05-31',
              '2023-06-30',
              '2023-07-31',
              '2023-08-30',
              '2023-09-31',
            ], 
          },
          title: {
            text: 'Περιστατικά',
            align: 'left',
          },
          tooltip: {
            enabled: true,
            theme: 'dark',
          },
        };

        break;
    }
  }
}
