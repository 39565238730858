import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RatingModule } from 'primeng/rating';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin, Subscription } from 'rxjs';
import { AppointmentsService, DoctorService, MemberService } from 'shared';

import { AngularOpenlayersModule } from 'ng-openlayers';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-doctor-details',
  standalone: true,
  imports: [
    AsyncPipe,
    DividerModule,
    RatingModule,
    FormsModule,
    DropdownModule,
    CarouselModule,
    TabViewModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    AngularOpenlayersModule,
    CalendarModule,
  ],
  templateUrl: './doctor-details.component.html',
  styleUrl: './doctor-details.component.scss',
})
export class DoctorDetailsComponent implements OnInit, OnDestroy {
  isModalOpen = false;
  doctor: any;
  userData: any;
  services: any = [];
  transportation: any = [];
  education: any = [];

  dropdownCenters: any;
  selectedCenter: any;
  dropdownServices: any;
  dropdownAppointmentType: any = [
    { name: 'In-Person', code: 'in-person' },
    { name: 'Video Call', code: 'videocall' },
  ];
  selectedAppointmentType: any = '';

  subscriptions: Subscription[] = [];
  dataLoaded: boolean = false;
  successVisible: boolean = false;
  errorVisible: boolean = false;
  submitDisable: boolean = false;
  serviceDateTimeModalVisible: boolean = false;

  availableDays: any[] = [];
  disabledDates: Date[] = []; // To hold unavailable dates
  availableDay: any;

  constructor(
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private appointmentService: AppointmentsService,
    private memberService: MemberService
  ) {}
  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.fetchData(id);
    this.availableDays = this.generateAvailableDaysForYear();
    this.setDisabledDates();
  }

  fetchData(id: number) {
    let userName = JSON.parse(localStorage.getItem('token')!);
    const memberServiceSubscription$ = this.memberService.getMemberByUsername(
      userName.userName
    );

    const doctorDataSubscription$ = this.doctorService.getDoctorByIdV2(id);

    const subscriptions$ = forkJoin([
      doctorDataSubscription$,
      memberServiceSubscription$,
    ]).subscribe({
      next: ([doctorDataResult, userDataResult]) => {
        if (doctorDataResult) {
          // console.log(doctorDataResult);
          this.doctor = doctorDataResult.data;
          this.dropdownCenters = this.doctor.addresses.map(
            (address: {
              fullAddress: any;
              practiceId: { toString: () => any };
            }) => ({
              name: address.fullAddress,
              code: address.practiceId.toString(),
            })
          );
          this.selectedCenter = this.dropdownCenters;
        }
        if (userDataResult) {
          // console.log('user', userDataResult);
          this.userData = userDataResult.data;
        }
      },
      error: (error) => {
        console.error('Error fetching data: ', error);
      },
      complete: () => {
        this.dataLoaded = true;
      },
    });

    this.subscriptions.push(subscriptions$);
  }

  ngOnDestroy(): void {
    this.subscriptions.pop();
  }

  selectedServiceForAppointment: any;
  selectedDateForAppointment: Date | undefined;
  selectedTimeForAppointment: string | undefined;

  openModal() {
    if (!this.selectedDay || !this.selectedHour) {
      this.serviceDateTimeModalVisible = true;
      return;
    }

    // Store the selected values in the component's state
    this.selectedServiceForAppointment = 'Απλή Επίσκεψη';
    this.selectedDateForAppointment = this.selectedDay.date;
    this.selectedTimeForAppointment = this.selectedHour;

    // Open the modal
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  onSubmit() {
    // console.log(this.userData)
    let appointment: any = {
      userId: this.userData.userId,
      requestUserId: this.userData.userId,
      responseUserId: this.doctor.userId,
      appointmentDate: this.formatDate(this.selectedDateForAppointment),
      appointmentTime: String(
        this.formatTime(this.selectedTimeForAppointment!)
      ),
      reasonOfVisit: this.selectedServiceForAppointment,
      type: 'In-Person',
    };
    this.appointmentService.createAppointment(appointment).subscribe({
      next: (res) => {
        // console.log(res);
        this.submitDisable = true;
        this.successVisible = true;
        this.closeModal();
      },
      error: (err) => {
        console.error(err);
        this.submitDisable = true;
        this.errorVisible = true;
      },
      complete: () => {},
    });
  }

  getRandomTimeSlots(): string[] {
    // Possible time slots between 9:00 AM to 5:00 PM (9 to 17 in 24-hour format)
    const timeSlots = [];
    for (let hour = 9; hour <= 17; hour++) {
      const formattedTime = new Date(0, 0, 0, hour).toLocaleTimeString(
        'en-US',
        {
          hour: '2-digit',
          minute: '2-digit',
        }
      );
      timeSlots.push(formattedTime);
    }

    // Randomize number of slots per day (min 1, max 5)
    const randomSlotCount = Math.floor(Math.random() * 5) + 1;
    const randomSlots = [];

    // Randomly pick time slots
    for (let i = 0; i < randomSlotCount; i++) {
      const randomIndex = Math.floor(Math.random() * timeSlots.length);
      randomSlots.push(timeSlots[randomIndex]);
      timeSlots.splice(randomIndex, 1); // Avoid duplicate slots
    }

    // Sort the time slots in ascending order
    return randomSlots.sort((a, b) => {
      const aMatch = a.match(/(\d+):(\d+)\s*(AM|PM)/);
      const bMatch = b.match(/(\d+):(\d+)\s*(AM|PM)/);

      if (!aMatch || !bMatch) {
        return 0; // If no match is found, don't change the order
      }

      const [, aHour, aMinute, aPeriod] = aMatch;
      const [, bHour, bMinute, bPeriod] = bMatch;

      // Convert to 24-hour format for proper comparison
      const aTime24 =
        (aPeriod === 'PM' && aHour !== '12'
          ? parseInt(aHour) + 12
          : parseInt(aHour)) *
          60 +
        parseInt(aMinute);
      const bTime24 =
        (bPeriod === 'PM' && bHour !== '12'
          ? parseInt(bHour) + 12
          : parseInt(bHour)) *
          60 +
        parseInt(bMinute);

      return aTime24 - bTime24;
    });
  }

  generateAvailableDaysForYear() {
    const availableDays = [];
    const today = new Date();
    const oneYearLater = new Date();
    oneYearLater.setFullYear(today.getFullYear() + 1); // Set date to one year later

    let currentDate = new Date(today);

    // Iterate through days until reaching one year later
    while (currentDate <= oneYearLater) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const day = currentDate.getDate();
      const dayOfWeek = currentDate.getDay();

      // Skip weekends (Saturday = 6, Sunday = 0)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const formattedDate = currentDate.toLocaleDateString('en-GB');
        const hours = this.getRandomTimeSlots();

        availableDays.push({
          date: formattedDate, // Formatted string for display
          hours: hours,
          actualDate: new Date(currentDate), // Actual Date object
        });
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return availableDays;
  }

  formatDate(date: any): string {
    // Split the input date string into day, month, and year
    const [day, month, year] = date.split('/');

    // Return the date in the desired yyyy/mm/dd format
    return `${year}-${month}-${day}`;
  }

  formatTime(timeStr: string): string {
    // Extract the hours and minutes part from the string
    let [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    // Convert to 24-hour format if needed
    if (period === 'PM' && hours < 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Format the hours and minutes with leading zeros
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine into the desired format
    return `${formattedHours}:${formattedMinutes}:00`;
  }
  selectedDay: any = null;
  selectedHour: any = null;

  minDate: Date = new Date(new Date().setDate(new Date().getDate() + 1));
  maxDate: Date = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ); // Maximum is one year ahead

  // Method triggered when a day is selected from the calendar
  onDateSelect() {
    const selectedDate = new Date(this.availableDay); // Ensure it's a Date object

    // Find the day in availableDays matching the selected date
    const matchedDay = this.availableDays.find((day: { date: string }) => {
      const [dayPart, monthPart, yearPart] = day.date.split('/');
      const dayDate = new Date(+yearPart, +monthPart - 1, +dayPart);
      return dayDate.toDateString() === selectedDate.toDateString();
    });

    // Keep the actual selected day as a Date object for p-calendar
    this.selectedDay = selectedDate;

    // Store the matched day for hours display
    this.selectedDay = matchedDay || null;

    // Reset selected hour if the day changes
    this.selectedHour = null;
  }

  selectDay(day: any) {
    if (day) {
      this.submitDisable = false;
      this.selectedDay = day;
    }
  }

  selectHour(hour: string) {
    if (hour) {
      this.selectedHour = hour;
    }
  }

  setDisabledDates() {
    const today = new Date();
    const oneYearLater = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    );

    let currentDate = new Date(this.minDate);

    // Reset disabledDates
    this.disabledDates = [];

    while (currentDate <= oneYearLater) {
      const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      // Skip weekends (Saturday = 6, Sunday = 0)
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        this.disabledDates.push(new Date(currentDate));
      } else {
        // Check if the day is in availableDays
        const isAvailable = this.availableDays.some(
          (availableDay: any) =>
            currentDate.toDateString() ===
            availableDay.actualDate.toDateString()
        );

        // If it's not available, add to disabledDates
        if (!isAvailable) {
          this.disabledDates.push(new Date(currentDate));
        }
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
}
