<div class="grid row-gap-3">
  <div class="col-12">
    <div class="grid">
      <!-- card -->
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-0 flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-user font-bold text-2xl text-white mr-2"></i>Επισκέπτες
          </h5>
          <h1 class="mb-1 m-0 text-white">27,000</h1>
          <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/manage-visits']"
          >
            Όλοι οι επισκέπτες
          </button>
        </div>
      </div>
      <!-- card -->
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-0 flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-user font-bold text-2xl text-white mr-2"></i
            >Ασθενείς
          </h5>
          <h1 class="mb-1 m-0 text-white">4,700</h1>
          <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/manage-patients']"
          >
            Όλοι οι ασθενείς
          </button>
        </div>
      </div>
      <!-- card -->
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
         >
            <h5 class="mb-1 m-0 text-white text-center p-3">
              <i class="pi pi-book font-bold text-2xl text-white mr-2"></i>Ιατροί
            </h5>
            <h1 class="mb-1 m-0 text-white">56</h1>
            <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/doctors']"
          >
            Όλοι οι ιατροί
          </button>
        </div>
      </div>
      <!-- card -->
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
            <h5 class="mb-1 m-0 text-white text-center p-3">
              <i class="pi pi-building font-bold text-xl text-white mr-2"></i>Εισαγωγές
            </h5>
            <h1 class="mb-1 m-0 text-white">6,700</h1>
            <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/manage-visits']"
          >
            Όλες οι εισαγωγές
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="lg:col-6 col-12">
    <app-dashboard-charts chartCase="top10"></app-dashboard-charts>
  </div>

  <div class="lg:col-6 col-12">
    <app-dashboard-charts chartCase="line"></app-dashboard-charts>
  </div>
  <div class="lg:col-12 col-12 card">
    <h5>Εισαγωγές σήμερα</h5>
    <p-table [value]="visits" stripedRows="true" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>Κωδ. Περιστατικού</th>
          <th>Ασθενής</th>
          <th>ΑΜΚΑ</th>
          <th>Ημερομηνία</th>
          <th>Ώρα</th>
          <th>Τύπος</th>
          <th>KEN</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-visit>
        <tr>
          <td>{{ visit.visitCode }}</td>
          <td>{{ visit.visitor }}</td>
          <td>{{ visit.amka }}</td>
          <td>{{ visit.date }}</td>
          <td>{{ visit.time }}</td>
          <td>{{ visit.referralCode }}</td>
          <td>{{ visit.executed ? 'Ναι' : 'Όχι' }}</td>
          <td><i class="pi pi-ellipsis-v cursor-pointer"></i></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
