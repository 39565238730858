import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { Appointment, AppointmentsService } from 'shared';
import { DashboardChartsComponent } from './dashboard-charts/dashboard-charts.component';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CurrencyPipe,
    TableModule,
    FormsModule,
    NgApexchartsModule,
    DashboardChartsComponent,
    ButtonModule,
    RouterLink
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, OnDestroy {
  rangeDates: Date[] | undefined;
  chartOptions: any;

  loading: boolean = false;

  subscriptions: Subscription[] = [];

  visits: any;

  constructor(private appointmentService: AppointmentsService) {}
  ngOnInit() {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fetchData() {
    this.visits = [
      {
        visitCode: 'V001',
        visitor: 'Γεωργία Παπαδοπούλου',
        amka: '12345678901',
        date: '2024-08-25',
        time: '10:00',
        referralCode: 'P001',
        executed: true
      },
      {
        visitCode: 'V002',
        visitor: 'Νίκος Παπακωνσταντίνου',
        amka: '10987654321',
        date: '2024-08-26',
        time: '11:30',
        referralCode: 'P002',
        executed: false
      },
      {
        visitCode: 'V003',
        visitor: 'Άννα Καραγιάννη',
        amka: '56789012345',
        date: '2024-08-27',
        time: '14:00',
        referralCode: 'P003',
        executed: true
      },
      {
        visitCode: 'V004',
        visitor: 'Μιχάλης Κωνσταντίνου',
        amka: '67890123456',
        date: '2024-08-28',
        time: '09:30',
        referralCode: 'P004',
        executed: false
      },
      {
        visitCode: 'V005',
        visitor: 'Ελένη Σταυροπούλου',
        amka: '78901234567',
        date: '2024-08-29',
        time: '16:00',
        referralCode: 'P005',
        executed: true
      }
    ];
  }

  getReasonSeverity(severity: any) {
    if (severity == 1) {
      return 'border-circle w-1rem h-1rem m-2 bg-blue-200 font-bold flex align-items-center justify-content-center"';
    } else if (severity == 2) {
      return 'border-circle w-1rem h-1rem m-2 bg-yellow-200 font-bold flex align-items-center justify-content-center';
    } else {
      return 'border-circle w-1rem h-1rem m-2 bg-red-200 font-bold flex align-items-center justify-content-center';
    }
  }
  getSeverity(
    arg0: any
  ):
    | 'success'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'contrast'
    | undefined {
    throw new Error('Method not implemented.');
  }
}
